// app configs
export const APP_NAME = "daily-football-stats"
export const APP_DISPLAY_NAME = "Soccer Price Monitor"
export const APP_VERSION = "v1.0"
export const COPYRIGHT_TEXT = "All Rights Reserved."
export const COGNITO_USER_SETTINGS_KEY = "custom:dailyFootballStats"
export const COGNITO_EMAIL_KEY = "email"
export const APP_ROLES = ["spm-users"] 
export const ODDS_BETS_365_XPATH = '$..oddResponses[?(@.name == "Bet365")]'
export const ODDS_BETS_PINNACLE_XPATH = '$..oddResponses[?(@.name == "Pinnacle")]'
export const ODDS_BETS_SBO_XPATH = '$..oddResponses[?(@.name == "SBO")]'
export const ODDS_BETS_BWIN_XPATH = '$..oddResponses[?(@.name == "Bwin")]'
export const ODDS_BETS_WILLIAM_HILL_XPATH = '$..oddResponses[?(@.name == "William Hill")]'
export const ODDS_BETS_MARATHON_BET_XPATH = '$..oddResponses[?(@.name == "Marathonbet")]'
export const SCORE_DELIMITER="."


export const LOGIN_STATUS = {
    LOGGED_IN: "LOGGED_IN",
    NOT_LOGGED_IN: " NOT_LOGGED_IN",
    FORGOT_PASSWORD_REQUEST: " FORGOT_PASSWORD_REQUEST",
    FORGOT_PASSWORD_PENDING_VERIFICATION: " FORGOT_PASSWORD_PENDING_VERIFICATION",
    FORGOT_PASSWORD_VERIFICATION_SUCCESS: "FORGOT_PASSWORD_VERIFICATION_SUCCESS",
    FORGOT_PASSWORD_VERIFICATION_FAILED: "FORGOT_PASSWORD_VERIFICATION_FAILED",
    CHANGE_PASSWORD: "CHANGE_PASSWORD"
}

// application static configs
export const JOCKEY_TABLE_COLUMNS_STATIC = {
    ROW_NUM: {id: "ROW_NUM", description: "#", jsonFieldId: null},
    NAME: {id: "NAME", description: "Name", jsonFieldId: "name"},
    WINS: {id: "WINS", description: "Wins", jsonFieldId: "wins"},
    RIDES: {id: "RIDES", description: "Races", jsonFieldId: "rides"},
    WIN_PERCENT: {id: "WIN_PERCENT", description: "Win %", jsonFieldId: "winPercent"},
    PLACE: {id: "PLACE", description: "Place", jsonFieldId: "place"},
    PLACE_PERCENT: {id: "PLACE_PERCENT", description: "Place%", jsonFieldId: "placePercent"},
    RACE: {id: "RACE", description: "Race", jsonFieldId: "race"},
};

export const HORSE_TABLE_COLUMNS_STATIC = {
    ROW_NUM: {id: "ROW_NUM", description: "#", jsonFieldId: null},
    DATE: {id: "DATE", description: "Date", jsonFieldId: ""},
    COUNTRY: {id: "COUNTRY", description: "Country", jsonFieldId: ""},
    TIME: {id: "TIME", description: "Time", jsonFieldId: ""},
    SCORE: {id: "SCORE", description: "Score", jsonFieldId: ""},
    EVENT: {id: "EVENT", description: "Event", jsonFieldId: ""},
    ODD_DETAILS: {id: "ODD_DETAILS ", description: "", jsonFieldId: ""},
    COMB_GOALS_SCORED_CONCEDED: {id: "COMB_GOALS_SCORED_CONCEDED", description: "Comb_GS", jsonFieldId: "", toolTip: "Average number of goals scored + Goal conceded by both teams "},
    COMB_OVER_TWO_POINT_FIVE: {id: "COMB_OVER_TWO_POINT_FIVE", description: "CO2.5", jsonFieldId: "", toolTip: "% of matches went above 2.5"},
    COMB_BTTS: {id: "COMB_BTTS", description: "CBTTS", jsonFieldId: "", toolTip: "% of matches where both team scored"},
    HOME_WIN_PERCENT: {id: "HOME_WIN_PERCENT", description: "H%", jsonFieldId: ""},
    DRAW_WIN_PERCENT: {id: "DRAW_WIN_PERCENT", description: "D%", jsonFieldId: ""},
    AWAY_WIN_PERCENT: {id: "AWAY_WIN_PERCENT", description: "A%", jsonFieldId: ""},
    ADVICE: {id: "ADVICE", description: "Advice", jsonFieldId: ""},
    
};

// application static configs
export const JOCKEY_TABLE_COLUMNS_HORSE = {
    HORSE: {id: "HORSE", description: "Horse", jsonFieldId: "runnerName"},
    AGE: {id: "AGE", description: "Age", jsonFieldId: "runner.metadata.AGE"},
    DAYS_SINCE_LAST_RUN: {id: "DAYS_SINCE_LAST_RUN", description: "Days Since Last Run", jsonFieldId: "runner.metadata.DAYS_SINCE_LAST_RUN"},
    FORM: {id: "FORM", description: "Form", jsonFieldId: "runner.metadata.FORM"},
    ODD_DETAILS: {id: "ODD_DETAILS ", description: "Odds(Back, Lay)", jsonFieldId: ""},
    WIN_DETAILS: {id: "WIN_DETAILS ", description: "Winner", jsonFieldId: ""},
    PREDICTIONS: {id: "PREDICTIONS ", description: "Predictions", jsonFieldId: ""},
};


// application static configs
export const LEAGUE_TABLE_COLUMNS_STATIC = {
    ROW_NUM: {id: "ROW_NUM", description: "#", jsonFieldId: null},
    COUNTRY_FLAG: {id: "COUNTRY_FLAG", description: "Flag", jsonFieldId: null},
    COUNTRY: {id: "COUNTRY", description: "Country", jsonFieldId: "country"},
    LEAGUE: {id: "LEAGUE", description: "League Name", jsonFieldId: "league"},
    MATCHES_PLAYED: {id: "MATCHES_PLAYED", description: "Matches Played", jsonFieldId: "matchesPlayed"},
    WINS_HOME: {id: "WINS_HOME", description: "Home Wins%", jsonFieldId: "winsHome"},
    DRAWS: {id: "DRAWS", description: "Drawn%", jsonFieldId: "draws"},
    WINS_AWAY: {id: "WINS_AWAY", description: "Away Wins%", jsonFieldId: "winsAway"},
    GOALS: {id: "GOALS", description: "Goals", jsonFieldId: "goals"},
    GOALS_HOME: {id: "GOALS_HOME", description: "Home Goals", jsonFieldId: "goalsHome"},
    GOALS_AWAY: {id: "GOALS_AWAY", description: "Away Goals", jsonFieldId: "goalsAway"},
    OVER_ONE_POINT_FIVE: {id: "OVER_ONE_POINT_FIVE", description: "O_1.5+%", jsonFieldId: "overOnePointFive"},
    OVER_TWO_POINT_FIVE: {id: "OVER_TWO_POINT_FIVE", description: "O_2.5+%", jsonFieldId: "overTwoPointFive"},
    OVER_THREE_POINT_FIVE: {id: "OVER_THREE_POINT_FIVE", description: "O_3.5+%", jsonFieldId: "overThreePointFive"},
    BTTS: {id: "BTTS", description: "BTTS%", jsonFieldId: "btts"}
};

export const LEAGUE_TABLE_COLUMNS_DYNAMIC = {

};

export const LEAGUE_TABLE_COLUMNS_ALL = {
    ...LEAGUE_TABLE_COLUMNS_STATIC,
    ...LEAGUE_TABLE_COLUMNS_DYNAMIC
};

export const JOCKEY_TABLE_COLUMNS_ALL = {
    ...JOCKEY_TABLE_COLUMNS_STATIC,
    ...JOCKEY_TABLE_COLUMNS_HORSE
};

export const MATCH_TABLE_COLUMNS_STATIC = {
    ROW_NUM: {id: "ROW_NUM", description: "#", jsonFieldId: null},
    COUNTRY_FLAG: {id: "COUNTRY_FLAG", description: "Flag", jsonFieldId: null},
    // COUNTRY_CODE: {id: "COUNTRY_CODE", description: "Country Code"},
    COUNTRY: {id: "COUNTRY", description: "Country", jsonFieldId: "country"},
    LEAGUE: {id: "LEAGUE", description: "League Name", jsonFieldId: "league"},
    HOME: {id: "HOME", description: "Home Team", jsonFieldId: "home"},
    AWAY: {id: "AWAY", description: "Away Team", jsonFieldId: "away"},
    TIME: {id: "TIME", description: "Time", jsonFieldId: "time"},
    DATE: {id: "DATE", description: "date", jsonFieldId: "date"},
};

export const MATCH_TABLE_COLUMNS_STATS_HOME = {
    H_GOAL_POINT: {id: "H_GOAL_POINT", description: "H_GP", jsonFieldId: "gpT", teamToolTip: "Games Played by Home as Home", totalToolTip: "Games Played by Home as Total ( Home +Away)"},
    H_GOAL_POINT_WIN: {id: "H_GOAL_POINT_WIN", description: "H_W%", jsonFieldId: "gpW", teamToolTip: "Win % for Home team as Home", totalToolTip: "Win % for Home team as Total ( Home + Away)"},
    H_GOAL_POINT_DRAW: {id: "H_GOAL_POINT_DRAW", description: "H_D%", jsonFieldId: "gpD", teamToolTip: "Draw % for Home team as Home", totalToolTip: "Draw % for Home team as Total ( Home + Away)"},
    H_GOAL_POINT_LOSE: {id: "H_GOAL_POINT_LOSE", description: "H_L%", jsonFieldId: "gpL", teamToolTip: "Loss % for Home team as Home", totalToolTip: "Loss % for Home team as Total ( Home + Away)"},
    H_POINT_PER_GAME: {id: "H_POINT_PER_GAME", description: "H_PPG", jsonFieldId: "goalPoint", teamToolTip: "Home team points per game as Home", totalToolTip: "Home team  points per game as Total ( Home + Away)"},
   
    H_GOALS_SCORED: {id: "H_GOALS_SCORED", description: "H_GS", jsonFieldId: "goalsScored", teamToolTip: "Total number of goals scored by Home team when played as Home", totalToolTip: "Total number of goals scored by Home team when played as Total( Home+Away)"},
    H_GOALS_CONCEDED: {id: "H_GOALS_CONCEDED", description: "H_GC", jsonFieldId: "goalsConceded", teamToolTip: "Total number of goals conceded by Home team when played as Home", totalToolTip: "Total number of goals conceded by Home team when played as Total( Home+Away)"},
    H_AVG_GOALS_SCORED: {id: "H_AVG_GOALS_SCORED", description: "H_GSPM", jsonFieldId: "avgGoalsScored", teamToolTip: "Total number of goals scored per match by Home team when played as Home", totalToolTip: "Total number of goals scored per match by Home team when played as Total( Home+Away)"},
    H_AVG_GOALS_CONCEDED: {id: "H_AVG_GOALS_CONCEDED", description: "H_GCPM", jsonFieldId: "avgGoalsConceded", teamToolTip: "Total number of goals conceded per match by Home team when played as Home", totalToolTip: "Total number of goals conceded per match by Home team when played as Total( Home+Away)"},
    H_AVG_GOALS_SCORED_CONCEDED: {id: "H_AVG_GOALS_SCORED_CONCEDED", description: "H_GS+GCPM", jsonFieldId: "avgGoalsScoredConc", teamToolTip: "Total number of ( Goal scored +goals conceded) per match by Home team when played as Home", totalToolTip: "Total number of ( goal scored +goals conceded) per match by Home team when played as Total( Home+Away)"},
    
    
    H_OVER_ONE_POINT_FIVE: {id: "H_OVER_ONE_POINT_FIVE", description: "H_O1.5%", jsonFieldId: "overOnePointFiveGoals", teamToolTip: "% of matches with 2 goals or more when Home team played as Home", totalToolTip: "% of matches with 2 goals or more when Home team played as Total ( Home + Away)"},
    H_OVER_TWO_POINT_FIVE: {id: "H_OVER_TWO_POINT_FIVE", description: "H_O2.5%", jsonFieldId: "overTwoPointFiveGoals", teamToolTip: "% of matches with 3 goals or more when Home team played as Home", totalToolTip: "% of matches with 3 goals or more when Home team played as Total ( Home + Away)"},
    H_OVER_THREE_POINT_FIVE: {id: "H_OVER_THREE_POINT_FIVE", description: "H_O3.5%", jsonFieldId: "overThreePointFiveGoals", teamToolTip: "% of matches with 4 goals or more when Home team played as Home", totalToolTip: "% of matches with 4 goals or more when Home team played as Total ( Home + Away)"},
    H_OVER_FOUR_POINT_FIVE: {id: "H_OVER_FOUR_POINT_FIVE", description: "H_O4.5%", jsonFieldId: "overFourPointFiveGoals", teamToolTip: "% of matches with 5 goals or more when Home team played as Home", totalToolTip: "% of matches with 5 goals or more when Home team played as Total ( Home + Away)"},
    H_OVER_FIVE_POINT_FIVE: {id: "H_OVER_FIVE_POINT_FIVE", description: "H_O5.5%", jsonFieldId: "overFivePointFiveGoals", teamToolTip: "% of matches with 6 goals or more when Home team played as Home", totalToolTip: "% of matches with 6 goals or more when Home team played as Total ( Home + Away)"},
    H_OVER_ZERO_POINT_FIVE_HT: {id: "H_OVER_ZERO_POINT_FIVE_HT", description: "H_0.5atHT%", jsonFieldId: "overZeroPointFiveGoalsHT", teamToolTip: "% of matches that were above 0.5 at Half time for Home when played as Home", totalToolTip: "% of matches that were above 0.5 at Half time for Home when played as Total ( Home + Away)"},
    H_OVER_ONE_POINT_FIVE_HT: {id: "H_OVER_ONE_POINT_FIVE_HT", description: "H_1.5atHT%", jsonFieldId: "overOnePointFiveGoalsHT", teamToolTip: "% of matches that were above 1.5 at Half time for Home when played as Home", totalToolTip: "% of matches that were above 1.5 at Half time for Home when played as Total ( Home + Away)"},
    H_OVER_TWO_POINT_FIVE_HT: {id: "H_OVER_TWO_POINT_FIVE_HT", description: "H_2.5atHT%", jsonFieldId: "overTwoPointFiveGoalsHT", teamToolTip: "% of matches that were above 2.5 at Half time for Home when played as Home", totalToolTip: "% of matches that were above 2.5 at Half time for Home when played as Total ( Home + Away)"},
    H_CLEAN_SHEETS: {id: "H_CLEAN_SHEETS", description: "H_Clean%", jsonFieldId: "cleanSheets", teamToolTip: "% of clean sheets for home team when played as home", totalToolTip: "% of clean sheets for Home team when played as Total ( Home + Away)"},
    H_WON_TO_NIL: {id: "H_WON_TO_NIL", description: "H_W2N%", jsonFieldId: "wonToNil", teamToolTip: "% of Won to Nil for home team when played as home", totalToolTip: "% of Won to Nil for Home team when played as Total ( Home + Away)"},
    H_SCORED_IN_BOTH_HALVES: {id: "H_SCORED_IN_BOTH_HALVES", description: "H_SIBH%", jsonFieldId: "scoredInBothHalves", teamToolTip: "% of matches where home team scored in both halves when played as home", totalToolTip: "% of matches where home team scored in both halves when played as Total ( Home + Away)"},
    H_BOTH_TEAMS_SCORED: {id: "H_BOTH_TEAMS_SCORED", description: "H_BTTS%", jsonFieldId: "bothTeamsScored", teamToolTip: "% of matches where both teams scored when Home team played as Home", totalToolTip: "% of matches where both teams scored when Home team played as Total ( Home + Away)"},
    H_FAILED_TO_SCORE: {id: "H_FAILED_TO_SCORE", description: "H_F2S%", jsonFieldId: "failedToScore", teamToolTip: "% of matches where Home team failed to score when played as Home", totalToolTip: "% of matches where Home team failed to score when played as Total ( Home + Away)"},
    H_LOST_TO_NIL: {id: "H_LOST_TO_NIL", description: "H_L2N%", jsonFieldId: "lostToNil", teamToolTip: "% of matches where Home team lost to nil when played as Home", totalToolTip: "% of matches where Home team lost to nil when played as Total ( Home + Away)"},
    H_CONCEDED_IN_BOTH_HALVES: {id: "H_CONCEDED_IN_BOTH_HALVES", description: "H_CIBH%", jsonFieldId: "concededInBothHalves", teamToolTip: "% of matches where home team conceded goals in both halves when played as Home", totalToolTip: "% of matches where home team conceded goals in both halves when played as Total ( Home + Away)"},

    H_CORNER_PER_MATCH: {id: "H_CORNER_PER_MATCH", description: "H_CORFORPM", jsonFieldId: "cpm", teamToolTip: "Home_Corners for per match as Home", totalToolTip: "Home_Corners for per match as Total (Home+ Away)"},
    H_CORNER_AGAINST_PER_MATCH: {id: "H_CORNER_AGAINST_PER_MATCH", description: "H_CORAGNPM", jsonFieldId: "capm", teamToolTip: "Home_Corners against per match as Home", totalToolTip: "Home_Corners against per match asTotal (Home+ Away)"},
    H_CORNER_TTL_PER_MATCH: {id: "H_CORNER_TTL_PER_MATCH", description: "H_TotalCor", jsonFieldId: "tcpm", teamToolTip: "Home_Total Corners  per match as Home", totalToolTip: "Home_Total Corners  per match as Total (Home+ Away)"},
    H_CORNER_OVER_9_5_PER_MATCH: {id: "H_CORNER_OVER_9_5_PER_MATCH", description: "H_Over9.5Cor% ", jsonFieldId: "o9_5", teamToolTip: "% of matches when Corners were over 10 as Home", totalToolTip: "% of matches when Corners were over 10 as Total (Home+ Away)"},
    H_CORNER_OVER_10_5_PER_MATCH: {id: "H_CORNER_OVER_10_5_PER_MATCH", description: "H_Over10.5Cor%", jsonFieldId: "o10_5", teamToolTip: "% of matches when Corners were over 11 as Home", totalToolTip: "% of matches when Corners were over 11 as Total (Home+ Away)"},
    H_CORNER_OVER_11_5_PER_MATCH: {id: "H_CORNER_OVER_11_5_PER_MATCH", description: "H_Over11.5Cor%", jsonFieldId: "o11_5", teamToolTip: "% of matches when Corners were over 12 as Home", totalToolTip: "% of matches when Corners were over 12 as Total (Home+ Away)"},
    H_CORNER_OVER_12_5_PER_MATCH: {id: "H_CORNER_OVER_12_5_PER_MATCH", description: "H_Over12.5Cor%", jsonFieldId: "o12_5", teamToolTip: "% of matches when Corners were over 13 as Home", totalToolTip: "% of matches when Corners were over 13as Total (Home+ Away)"},
    H_CORNER_OVER_13_5_PER_MATCH: {id: "H_CORNER_OVER_13_5_PER_MATCH", description: "H_Over13.5Cor%", jsonFieldId: "o13_5", teamToolTip: "% of matches when Corners were over 14 as Home", totalToolTip: "% of matches when Corners were over 14 as Total (Home+ Away)"},
    
    
}

export const MATCH_TABLE_COLUMNS_STATS_AWAY = {
    A_GOAL_POINT: {id: "A_GOAL_POINT", description: "A_GP", jsonFieldId: "gpT", teamToolTip: "Games Played by Away as Away", totalToolTip: "Games Played by Away as Total ( Away +Away)"},
    A_GOAL_POINT_WIN: {id: "A_GOAL_POINT_WIN", description: "A_W%", jsonFieldId: "gpW", teamToolTip: "Win % for Away team as Away", totalToolTip: "Win % for Away team as Total ( Away + Away)"},
    A_GOAL_POINT_DRAW: {id: "A_GOAL_POINT_DRAW", description: "A_D%", jsonFieldId: "gpD", teamToolTip: "Draw % for Away team as Away", totalToolTip: "Draw % for Away team as Total ( Away + Away)"},
    A_GOAL_POINT_LOSE: {id: "A_GOAL_POINT_LOSE", description: "A_L%", jsonFieldId: "gpL", teamToolTip: "Loss % for Away team as Away", totalToolTip: "Loss % for Away team as Total ( Away + Away)"},
    A_POINT_PER_GAME: {id: "A_POINT_PER_GAME", description: "A_PPG", jsonFieldId: "goalPoint", teamToolTip: "Away team points per game as Away", totalToolTip: "Away team  points per game as Total ( Away + Away)"},
   
    A_GOALS_SCORED: {id: "A_GOALS_SCORED", description: "A_GS", jsonFieldId: "goalsScored", teamToolTip: "Total number of goals scored by Away team when played as Away", totalToolTip: "Total number of goals scored by Away team when played as Total( Home+Away)"},
    A_GOALS_CONCEDED: {id: "A_GOALS_CONCEDED", description: "A_GC", jsonFieldId: "goalsConceded", teamToolTip: "Total number of goals conceded by Away team when played as Away", totalToolTip: "Total number of goals conceded by Away team when played as Total( Home+Away)"},
    A_AVG_GOALS_SCORED: {id: "A_AVG_GOALS_SCORED", description: "A_GSPM", jsonFieldId: "avgGoalsScored", teamToolTip: "Total number of goals scored per match by Away team when played as Away", totalToolTip: "Total number of goals scored per match by Away team when played as Total( Home+Away)"},
    A_AVG_GOALS_CONCEDED: {id: "A_AVG_GOALS_CONCEDED", description: "A_GCPM", jsonFieldId: "avgGoalsConceded", teamToolTip: "Total number of goals conceded  per match by Away team when played as Away", totalToolTip: "Total number of goals conceded per match by Away team when played as Total( Home+Away)"},
    A_AVG_GOALS_SCORED_CONCEDED: {id: "A_AVG_GOALS_SCORED_CONCEDED", description: "A_GS+GCPM", jsonFieldId: "avgGoalsScoredConc", teamToolTip: "Total number of ( goal scored +goals conceded)  per match by Away team when played as Away", totalToolTip: "Total number of ( goal scored +goals conceded) per match by Away team when played as Total( Home+Away)"},
    A_OVER_ONE_POINT_FIVE: {id: "A_OVER_ONE_POINT_FIVE", description: "A_O1.5%", jsonFieldId: "overOnePointFiveGoals", teamToolTip: "% of matches with 2 goals or more when Away team played as Away", totalToolTip: "Away team played as Total ( Home + Away)"},
    A_OVER_TWO_POINT_FIVE: {id: "A_OVER_TWO_POINT_FIVE", description: "A_O2.5%", jsonFieldId: "overTwoPointFiveGoals", teamToolTip: "% of matches with 3 goals or more when Away team played as Away", totalToolTip: "% of matches with 3 goals or more when Away team played as Total ( Home + Away)"},
    A_OVER_THREE_POINT_FIVE: {id: "A_OVER_THREE_POINT_FIVE", description: "A_O3.5%", jsonFieldId: "overThreePointFiveGoals", teamToolTip: "% of matches with 4 goals or more when Away team played as Away", totalToolTip: "% of matches with 4 goals or more when Away team played as Total ( Home + Away)"},
    A_OVER_FOUR_POINT_FIVE: {id: "A_OVER_FOUR_POINT_FIVE", description: "A_O4.5%", jsonFieldId: "overFourPointFiveGoals", teamToolTip: "% of matches with 5 goals or more when Away team played as Away", totalToolTip: "% of matches with 5 goals or more when Away team played as Total ( Home + Away)"},
    A_OVER_FIVE_POINT_FIVE: {id: "A_OVER_FIVE_POINT_FIVE", description: "A_O5.5%", jsonFieldId: "overFivePointFiveGoals", teamToolTip: "% of matches with 6 goals or more when Away team played as Away", totalToolTip: "% of matches with 6 goals or more when Away team played as Total ( Home + Away)"},
    A_OVER_ZERO_POINT_FIVE_HT: {id: "A_OVER_ZERO_POINT_FIVE_HT", description: "A_0.5atHT%", jsonFieldId: "overZeroPointFiveGoalsHT", teamToolTip: "% of matches that were above 0.5 at Half time for Away when played as Away", totalToolTip: "% of matches that were above 0.5 at Half time for Away when played as Total ( Home + Away)"},
    A_OVER_ONE_POINT_FIVE_HT: {id: "A_OVER_ONE_POINT_FIVE_HT", description: "A_1.5atHT%", jsonFieldId: "overOnePointFiveGoalsHT", teamToolTip: "% of matches that were above 1.5 at Half time for Away when played as Away", totalToolTip: "% of matches that were above 1.5 at Half time for Away when played as Total ( Home + Away)"},
    A_OVER_TWO_POINT_FIVE_HT: {id: "A_OVER_TWO_POINT_FIVE_HT", description: "A_2.5atHT%", jsonFieldId: "overTwoPointFiveGoalsHT", teamToolTip: "% of matches that were above 2.5 at Half time for Away when played as Away", totalToolTip: "% of matches that were above 2.5 at Half time for Away when played as Total ( Home + Away)"},
    A_CLEAN_SHEETS: {id: "A_CLEAN_SHEETS", description: "A_Clean%", jsonFieldId: "cleanSheets", teamToolTip: "% of clean sheets for Away team when played as Away", totalToolTip: "% of clean sheets for Away team when played as Total ( Home + Away)"},
    A_WON_TO_NIL: {id: "A_WON_TO_NIL", description: "A_W2N%", jsonFieldId: "wonToNil", teamToolTip: "% of Won to Nil for Away team when played as Away", totalToolTip: "% of Won to Nil  for Away team when played as Total ( Home + Away)"},
    A_SCORED_IN_BOTH_HALVES: {id: "A_SCORED_IN_BOTH_HALVES", description: "A_SIBH%", jsonFieldId: "scoredInBothHalves", teamToolTip: "% of matches where Away team scored in both halves when played as Away", totalToolTip: "% of matches where Away team scored in both halves when played as Total ( Home + Away)"},
    A_BOTH_TEAMS_SCORED: {id: "A_BOTH_TEAMS_SCORED", description: "A_BTTS%", jsonFieldId: "bothTeamsScored", teamToolTip: "% of matches where both teams scored when Away team played as Away", totalToolTip: "% of matches where both teams scored when Away team played as Total ( Home + Away)"},
    A_FAILED_TO_SCORE: {id: "A_FAILED_TO_SCORE", description: "A_F2S%", jsonFieldId: "failedToScore", teamToolTip: "% of matches where Away team failed to score when played as Away", totalToolTip: "% of matches where Away  team failed to score when played as Total ( Home + Away)"},
    A_LOST_TO_NIL: {id: "A_LOST_TO_NIL", description: "A_L2N%", jsonFieldId: "lostToNil", teamToolTip: "% of matches where Away team lost to nil when played as Away", totalToolTip: "% of matches where Away team lost to nil when played as Total ( Home + Away)"},
    A_CONCEDED_IN_BOTH_HALVES: {id: "A_CONCEDED_IN_BOTH_HALVES", description: "A_CIBH%", jsonFieldId: "concededInBothHalves", teamToolTip: "% of matches where away team conceded goals in both halves when played as Away", totalToolTip: "% of matches where away team conceded goals in both halves when played as Total ( home + Away)"},
   
    A_CORNER_PER_MATCH: {id: "A_CORNER_PER_MATCH", description: "A_CORFORPM", jsonFieldId: "cpm", teamToolTip: "Away_Corners for per match as Away", totalToolTip: "Away_Corners for per match as Total (Away+ Away)"},
    A_CORNER_AGAINST_PER_MATCH: {id: "A_CORNER_AGAINST_PER_MATCH", description: "A_CORAGNPM", jsonFieldId: "capm", teamToolTip: "Away_Corners against per match as Away", totalToolTip: "Away_Corners against per match asTotal (Away+ Away)"},
    A_CORNER_TTL_PER_MATCH: {id: "A_CORNER_TTL_PER_MATCH", description: "A_TotalCor", jsonFieldId: "tcpm", teamToolTip: "Away_Total Corners  per match as Away", totalToolTip: "Away_Total Corners  per match as Total (Away+ Away)"},
    A_CORNER_OVER_9_5_PER_MATCH: {id: "A_CORNER_OVER_9_5_PER_MATCH", description: "A_Over9.5Cor%", jsonFieldId: "o9_5", teamToolTip: "% of matches when Corners were over 10 as Away", totalToolTip: "% of matches when Corners were over 10 as Total (Away+ Away)"},
    A_CORNER_OVER_10_5_PER_MATCH: {id: "A_CORNER_OVER_10_5_PER_MATCH", description: "A_Over10.5Cor%", jsonFieldId: "o10_5", teamToolTip: "% of matches when Corners were over 11 as Away", totalToolTip: "% of matches when Corners were over 11 as Total (Away+ Away)"},
    A_CORNER_OVER_11_5_PER_MATCH: {id: "A_CORNER_OVER_11_5_PER_MATCH", description: "A_Over11.5Cor%", jsonFieldId: "o11_5", teamToolTip: "% of matches when Corners were over 12 as Away", totalToolTip: "% of matches when Corners were over 12 as Total (Away+ Away)"},
    A_CORNER_OVER_12_5_PER_MATCH: {id: "A_CORNER_OVER_12_5_PER_MATCH", description: "A_Over12.5Cor%", jsonFieldId: "o12_5", teamToolTip: "% of matches when Corners were over 13 as Away", totalToolTip: "% of matches when Corners were over 13 as Total (Away+ Away)"},
    A_CORNER_OVER_13_5_PER_MATCH: {id: "A_CORNER_OVER_13_5_PER_MATCH", description: "A_Over13.5Cor% ", jsonFieldId: "o13_5", teamToolTip: "% of matches when Corners were over 14 as Away", totalToolTip: "% of matches when Corners were over 14 as Total (Away+ Away)"},
    
}



export const MATCH_TABLE_COLUMNS_RESULTS = {
    RESULT_FT: {id: "RESULT_FT", description: "Result FT", jsonFieldId: "resultFT"}
    // RESULT_HT: {id: "RESULT_HT", description: "Result HT", jsonFieldId: "resultHT"}
}
export const MATCH_TABLE_COLUMNS_ODDS = {
    ODDS_HOME_TEAM: {id: "ODDS_HOME_TEAM", description: "Home Team", jsonFieldId: "matchOdd", toolTip: "Home Team Name"},
    ODDS_AWAY_TEAM: {id: "ODDS_AWAY_TEAM", description: "Away Team", jsonFieldId: "matchOdd", toolTip: "Away Team Name"},

    ODDS_STATUS: {id: "ODDS_STATUS", description: "Status", jsonFieldId: "matchOdd", toolTip: "Match Status"},

    ODDS_FINAL_SCORE: {id: "ODDS_FINAL_SCORE", description: "FT Result", jsonFieldId: "matchOdd", toolTip: "Final Score"},
    ODDS_HALF_TIME_SCORE: {id: "ODDS_HALF_TIME_SCORE", description: "HT Result", jsonFieldId: "matchOdd", toolTip: "Half Time Result"},
    ODDS_SECOND_HALF_TIME_SCORE: {id: "ODDS_SECOND_HALF_TIME_SCORE", description: "2nd HT Result", jsonFieldId: "matchOdd", toolTip: "2nd Half Time Result"},
    ODDS_TOTAL_GOALS: {id: "ODDS_TOTAL_GOALS", description: "Total Goals", jsonFieldId: "matchOdd", toolTip: "Total Goals"},
    ODDS_TOTAL_HALF_TIME_GOAL: {id: "ODDS_TOTAL_HALF_TIME_GOAL", description: "Total 1st HT Goals", jsonFieldId: "matchOdd", toolTip: "Total 1st Half Time Goals"},
    ODDS_TOTAL_SCOND_HALF_TIME_GOAL: {id: "ODDS_TOTAL_SCOND_HALF_TIME_GOAL", description: "Total 2nd HT Goals", jsonFieldId: "matchOdd", toolTip: "Total 2nd Halt Time Goals"},

    ODDS_MATCH_WINNER_HOME: {id: "ODDS_MATCH_WINNER_HOME", description: "FT(H)", jsonFieldId: "matchOdd", toolTip: "Match Winner(Home)", jsonPath:'bets[?(@.name== "Match Winner")].values[?(@.value== "Home" )].odd'},
    ODDS_MATCH_WINNER_DRAW: {id: "ODDS_MATCH_WINNER_DRAW", description: "FT(D)", jsonFieldId: "matchOdd", toolTip: "Match Winner(Draw)", jsonPath:'bets[?(@.name== "Match Winner")].values[?(@.value== "Draw" )].odd'},
    ODDS_MATCH_WINNER_AWAY: {id: "ODDS_MATCH_WINNER_AWAY", description: "FT(A)", jsonFieldId: "matchOdd", toolTip: "Match Winner(Away)", jsonPath:'bets[?(@.name== "Match Winner")].values[?(@.value== "Away" )].odd'},

    ODDS_UNDER_0_5: {id: "ODDS_UNDER_0_5", description: "U0.5", jsonFieldId: "matchOdd", toolTip: "Under 0.5", jsonPath:'bets[?(@.name== "Goals Over/Under")].values[?(@.value== "Under 0.5" )].odd'},
    ODDS_OVER_0_5: {id: "ODDS_OVER_0_5", description: "O0.5", jsonFieldId: "matchOdd", toolTip: "Over 0.5",    jsonPath:'bets[?(@.name== "Goals Over/Under")].values[?(@.value== "Over 0.5" )].odd'},
    ODDS_UNDER_1_5: {id: "ODDS_UNDER_1_5", description: "U1.5", jsonFieldId: "matchOdd", toolTip: "Under 1.5", jsonPath:'bets[?(@.name== "Goals Over/Under")].values[?(@.value== "Under 1.5" )].odd'},
    ODDS_OVER_1_5: {id: "ODDS_OVER_1_5", description: "O1.5", jsonFieldId: "matchOdd", toolTip: "Over 1.5",    jsonPath:'bets[?(@.name== "Goals Over/Under")].values[?(@.value== "Over 1.5" )].odd'},
    ODDS_UNDER_2_5: {id: "ODDS_UNDER_2_5", description: "U2.5", jsonFieldId: "matchOdd", toolTip: "Under 2.5", jsonPath:'bets[?(@.name== "Goals Over/Under")].values[?(@.value== "Under 2.5" )].odd'},
    ODDS_OVER_2_5: {id: "ODDS_OVER_2_5", description: "O2.5", jsonFieldId: "matchOdd", toolTip: "Over 2.5" ,   jsonPath:'bets[?(@.name== "Goals Over/Under")].values[?(@.value== "Over 2.5" )].odd'},
    ODDS_UNDER_3_5: {id: "ODDS_UNDER_3_5", description: "U3.5", jsonFieldId: "matchOdd", toolTip: "Under 3.5", jsonPath:'bets[?(@.name== "Goals Over/Under")].values[?(@.value== "Under 3.5" )].odd'},
    ODDS_OVER_3_5: {id: "ODDS_OVER_3_5", description: "O3.5", jsonFieldId: "matchOdd", toolTip: "Over 3.5",    jsonPath:'bets[?(@.name== "Goals Over/Under")].values[?(@.value== "Over 3.5" )].odd'},
    ODDS_UNDER_4_5: {id: "ODDS_UNDER_4_5", description: "U4.5", jsonFieldId: "matchOdd", toolTip: "Under 4.5", jsonPath:'bets[?(@.name== "Goals Over/Under")].values[?(@.value== "Under 4.5" )].odd'},
    ODDS_OVER_4_5: {id: "ODDS_OVER_4_5", description: "O4.5", jsonFieldId: "matchOdd", toolTip: "Over 4.5",    jsonPath:'bets[?(@.name== "Goals Over/Under")].values[?(@.value== "Over 3.5" )].odd'},

    ODDS_FIRST_HALF_WINNER_HOME:  {id: "ODDS_FIRST_HALF_WINNER_HOME", description: "1st Half(H)", jsonFieldId: "matchOdd", toolTip: "1st Half Winner Home", jsonPath:'bets[?(@.name== "First Half Winner")].values[?(@.value== "Home" )].odd'},
    ODDS_FIRST_HALF_WINNER_AWAY:  {id: "ODDS_FIRST_HALF_WINNER_AWAY", description: "1st Half(A)", jsonFieldId: "matchOdd", toolTip: "1st Half Winner Away", jsonPath:'bets[?(@.name== "First Half Winner")].values[?(@.value== "Away" )].odd'},
    ODDS_FIRST_HALF_WINNER_DRAW:  {id: "ODDS_FIRST_HALF_WINNER_DRAW", description: "1st Half(D)", jsonFieldId: "matchOdd", toolTip: "1st Half Winner Draw", jsonPath:'bets[?(@.name== "First Half Winner")].values[?(@.value== "Draw" )].odd'},
    ODDS_SECOND_HALF_WINNER_HOME: {id: "ODDS_SECOND_HALF_WINNER_HOME", description: "2nd Half(H)", jsonFieldId: "matchOdd", toolTip: "2nd Half Winner Home", jsonPath:'bets[?(@.name== "Second Half Winner")].values[?(@.value== "Home" )].odd'},
    ODDS_SECOND_HALF_WINNER_AWAY: {id: "ODDS_SECOND_HALF_WINNER_AWAY", description: "2nd Half(A)", jsonFieldId: "matchOdd", toolTip: "2nd Half Winner Away", jsonPath:'bets[?(@.name== "Second Half Winner")].values[?(@.value== "Away" )].odd'},
    ODDS_SECOND_HALF_WINNER_DRAW: {id: "ODDS_SECOND_HALF_WINNER_DRAW", description: "2nd Half(D)", jsonFieldId: "matchOdd", toolTip: "2nd Half Winner Draw", jsonPath:'bets[?(@.name== "Second Half Winner")].values[?(@.value== "Draw" )].odd'},


    ODDS_DOUBLE_CHANCE_HOME_DRAW: {id: "ODDS_DOUBLE_CHANCE_HOME_DRAW", description: "DC(HD)", jsonFieldId: "matchOdd", toolTip: "Double Chance - ( Home/Draw)" , jsonPath:'bets[?(@.name== "Double Chance")].values[?(@.value== "Home/Draw" )].odd'},
    ODDS_DOUBLE_CHANCE_HOME_AWAY: {id: "ODDS_DOUBLE_CHANCE_HOME_AWAY", description: "DC(HA)", jsonFieldId: "matchOdd", toolTip: "Double Chance - ( Home/Away)" , jsonPath:'bets[?(@.name== "Double Chance")].values[?(@.value== "Home/Away" )].odd'},
    ODDS_DOUBLE_CHANCE_DRAW_AWAY: {id: "ODDS_DOUBLE_CHANCE_DRAW_AWAY", description: "DC(DA)", jsonFieldId: "matchOdd", toolTip: "Double Chance -( Draw/Away)", jsonPath:'bets[?(@.name== "Double Chance")].values[?(@.value== "Draw/Away" )].odd'},

    ODDS_ODD_EVEN_EVEN: {id: "ODDS_ODD_EVEN_EVEN", description: "OddEven(E)", jsonFieldId: "matchOdd", toolTip: "Odds Even- (Even)", jsonPath:'bets[?(@.name== "Odd/Even")].values[?(@.value== "Even" )].odd'},
    ODDS_ODD_EVEN_ODD: {id: "ODDS_ODD_EVEN_ODD", description: "OddEven(O)", jsonFieldId: "matchOdd", toolTip: "Odds Even- (Odds)" , jsonPath:'bets[?(@.name== "Odd/Even")].values[?(@.value== "Odd" )].odd'},
    ODDS_ODD_EVEN_FIRST_HALF_EVEN: {id: "ODDS_ODD_EVEN_FIRST_HALF_EVEN", description: "OddEven 1st Half(E)", jsonFieldId: "matchOdd", toolTip: "Odds Even  1st Half- (Even)" , jsonPath:'bets[?(@.name== "Odd/Even - First Half")].values[?(@.value== "Even" )].odd'},
    ODDS_ODD_EVEN_FIRST_HALF_ODD:  {id: "ODDS_ODD_EVEN_FIRST_HALF_ODD", description: "OddEven 1st Half(O)", jsonFieldId: "matchOdd", toolTip: "Odds Even  1st Half- (Odd)" , jsonPath:'bets[?(@.name== "Odd/Even - First Half")].values[?(@.value== "Odd" )].odd'},
    ODDS_ODD_EVEN_SECOND_HALF_EVEN: {id: "ODDS_ODD_EVEN_SECOND_HALF_EVEN", description: "OddEven 2nd Half(E)", jsonFieldId: "matchOdd", toolTip: "Odds Even 2nd Half- (Even)", jsonPath:'bets[?(@.name== "Odd/Even - Second Half")].values[?(@.value== "Even" )].odd'},
    ODDS_ODD_EVEN_SECOND_HALF_ODD: {id: "ODDS_ODD_EVEN_SECOND_HALF_ODD", description: "OddEven 2nd Half(O)", jsonFieldId: "matchOdd", toolTip: "Odds Even 2nd Half- (Odd)" , jsonPath:'bets[?(@.name== "Odd/Even - Second Half")].values[?(@.value== "Odd" )].odd'},

    ODDS_HTFT_HOME_HOME: {id: "ODDS_HTFT_HOME_HOME", description: "HTFT(H/H)", jsonFieldId: "matchOdd", toolTip: "HT/FT- Home/Home", jsonPath:'bets[?(@.name== "HT/FT Double")].values[?(@.value== "Home/Home" )].odd'},
    ODDS_HTFT_DRAW_DRAW: {id: "ODDS_HTFT_DRAW_DRAW", description: "HTFT(D/D)", jsonFieldId: "matchOdd", toolTip: "HT/FT- Draw/Draw", jsonPath:'bets[?(@.name== "HT/FT Double")].values[?(@.value== "Draw/Draw" )].odd'},
    ODDS_HTFT_DRAW_AWAY: {id: "ODDS_HTFT_DRAW_AWAY", description: "HTFT(D/A)", jsonFieldId: "matchOdd", toolTip: "HT/FT- Draw/Away", jsonPath:'bets[?(@.name== "HT/FT Double")].values[?(@.value== "Draw/Away" )].odd'},
    ODDS_HTFT_HOME_AWAY: {id: "ODDS_HTFT_HOME_AWAY", description: "HTFT(H/A)", jsonFieldId: "matchOdd", toolTip: "HT/FT- Home/Away", jsonPath:'bets[?(@.name== "HT/FT Double")].values[?(@.value== "Home/Away" )].odd'},
    ODDS_HTFT_HOME_DRAW: {id: "ODDS_HTFT_HOME_DRAW", description: "HTFT(H/D)", jsonFieldId: "matchOdd", toolTip: "HT/FT- Home/Draw", jsonPath:'bets[?(@.name== "HT/FT Double")].values[?(@.value== "Home/Draw" )].odd'},
    ODDS_HTFT_AWAY_AWAY: {id: "ODDS_HTFT_AWAY_AWAY", description: "HTFT(A/A)", jsonFieldId: "matchOdd", toolTip: "HT/FT- Away/Away", jsonPath:'bets[?(@.name== "HT/FT Double")].values[?(@.value== "Away/Away" )].odd'},
    ODDS_HTFT_AWAY_DRAW: {id: "ODDS_HTFT_AWAY_DRAW", description: "HTFT(A/D)", jsonFieldId: "matchOdd", toolTip: "HT/FT- Away/Draw", jsonPath:'bets[?(@.name== "HT/FT Double")].values[?(@.value== "Away/Draw" )].odd'},
    ODDS_HTFT_AWAY_HOME: {id: "ODDS_HTFT_AWAY_HOME", description: "HTFT(A/H)", jsonFieldId: "matchOdd", toolTip: "HT/FT- Away/Home", jsonPath:'bets[?(@.name== "HT/FT Double")].values[?(@.value== "Away/Home" )].odd'},
    ODDS_HTFT_DRAW_HOME: {id: "ODDS_HTFT_DRAW_HOME", description: "HTFT(D/H)", jsonFieldId: "matchOdd", toolTip: "HT/FT- Draw/Home", jsonPath:'bets[?(@.name== "HT/FT Double")].values[?(@.value== "Draw/Home" )].odd'},


    ODDS_CORRECT_SCORE_00: {id: "ODDS_CORRECT_SCORE_00", description: "CS(0-0)", jsonFieldId: "matchOdd", toolTip: "Exact Score 0-0", jsonPath:'bets[?(@.name== "Exact Score")].values[?(@.value== "0:0" )].odd'},
    ODDS_CORRECT_SCORE_01: {id: "ODDS_CORRECT_SCORE_01", description: "CS(0-1)", jsonFieldId: "matchOdd", toolTip: "Exact Score 0-1", jsonPath:'bets[?(@.name== "Exact Score")].values[?(@.value== "0:1" )].odd'},
    ODDS_CORRECT_SCORE_02: {id: "ODDS_CORRECT_SCORE_02", description: "CS(0-2)", jsonFieldId: "matchOdd", toolTip: "Exact Score 0-2", jsonPath:'bets[?(@.name== "Exact Score")].values[?(@.value== "0:2" )].odd'},
    ODDS_CORRECT_SCORE_03: {id: "ODDS_CORRECT_SCORE_03", description: "CS(0-3)", jsonFieldId: "matchOdd", toolTip: "Exact Score 0-3", jsonPath:'bets[?(@.name== "Exact Score")].values[?(@.value== "0:3" )].odd'},
    ODDS_CORRECT_SCORE_10: {id: "ODDS_CORRECT_SCORE_10", description: "CS(1-0)", jsonFieldId: "matchOdd", toolTip: "Exact Score 1-0", jsonPath:'bets[?(@.name== "Exact Score")].values[?(@.value== "1:0" )].odd'},
    ODDS_CORRECT_SCORE_11: {id: "ODDS_CORRECT_SCORE_11", description: "CS(1-1)", jsonFieldId: "matchOdd", toolTip: "Exact Score 1-1", jsonPath:'bets[?(@.name== "Exact Score")].values[?(@.value== "1:1" )].odd'},
    ODDS_CORRECT_SCORE_12: {id: "ODDS_CORRECT_SCORE_12", description: "CS(1-2)", jsonFieldId: "matchOdd", toolTip: "Exact Score 1-2", jsonPath:'bets[?(@.name== "Exact Score")].values[?(@.value== "1:2" )].odd'},
    ODDS_CORRECT_SCORE_13: {id: "ODDS_CORRECT_SCORE_13", description: "CS(1-3)", jsonFieldId: "matchOdd", toolTip: "Exact Score 1-3", jsonPath:'bets[?(@.name== "Exact Score")].values[?(@.value== "1:3" )].odd'},
    ODDS_CORRECT_SCORE_20: {id: "ODDS_CORRECT_SCORE_20", description: "CS(2-0)", jsonFieldId: "matchOdd", toolTip: "Exact Score 2-0", jsonPath:'bets[?(@.name== "Exact Score")].values[?(@.value== "2:0" )].odd'},
    ODDS_CORRECT_SCORE_21: {id: "ODDS_CORRECT_SCORE_21", description: "CS(2-1)", jsonFieldId: "matchOdd", toolTip: "Exact Score 2-1", jsonPath:'bets[?(@.name== "Exact Score")].values[?(@.value== "2:1" )].odd'},
    ODDS_CORRECT_SCORE_22: {id: "ODDS_CORRECT_SCORE_22", description: "CS(2-2)", jsonFieldId: "matchOdd", toolTip: "Exact Score 2-2", jsonPath:'bets[?(@.name== "Exact Score")].values[?(@.value== "2:2" )].odd'},
    ODDS_CORRECT_SCORE_23: {id: "ODDS_CORRECT_SCORE_23", description: "CS(2-3)", jsonFieldId: "matchOdd", toolTip: "Exact Score 2-3", jsonPath:'bets[?(@.name== "Exact Score")].values[?(@.value== "2:3" )].odd'},
    ODDS_CORRECT_SCORE_30: {id: "ODDS_CORRECT_SCORE_30", description: "CS(3-0)", jsonFieldId: "matchOdd", toolTip: "Exact Score 3-0", jsonPath:'bets[?(@.name== "Exact Score")].values[?(@.value== "3:0" )].odd'},
    ODDS_CORRECT_SCORE_31: {id: "ODDS_CORRECT_SCORE_31", description: "CS(3-1)", jsonFieldId: "matchOdd", toolTip: "Exact Score 3-1", jsonPath:'bets[?(@.name== "Exact Score")].values[?(@.value== "3:1" )].odd'},
    ODDS_CORRECT_SCORE_32: {id: "ODDS_CORRECT_SCORE_32", description: "CS(3-2)", jsonFieldId: "matchOdd", toolTip: "Exact Score 3-2", jsonPath:'bets[?(@.name== "Exact Score")].values[?(@.value== "3:2" )].odd'},
    ODDS_CORRECT_SCORE_33: {id: "ODDS_CORRECT_SCORE_33", description: "CS(3-3)", jsonFieldId: "matchOdd", toolTip: "Exact Score 3-3", jsonPath:'bets[?(@.name== "Exact Score")].values[?(@.value== "3:3" )].odd'},
    ODDS_CORRECT_SCORE_14: {id: "ODDS_CORRECT_SCORE_14", description: "CS(1-4)", jsonFieldId: "matchOdd", toolTip: "Exact Score 1-4", jsonPath:'bets[?(@.name== "Exact Score")].values[?(@.value== "1:4" )].odd'},
    ODDS_CORRECT_SCORE_24: {id: "ODDS_CORRECT_SCORE_24", description: "CS(2-4)", jsonFieldId: "matchOdd", toolTip: "Exact Score 2-4", jsonPath:'bets[?(@.name== "Exact Score")].values[?(@.value== "2:4" )].odd'},
    ODDS_CORRECT_SCORE_34: {id: "ODDS_CORRECT_SCORE_34", description: "CS(3-4)", jsonFieldId: "matchOdd", toolTip: "Exact Score 3-4", jsonPath:'bets[?(@.name== "Exact Score")].values[?(@.value== "3:4" )].odd'},
    ODDS_CORRECT_SCORE_40: {id: "ODDS_CORRECT_SCORE_40", description: "CS(4-0)", jsonFieldId: "matchOdd", toolTip: "Exact Score 4-0", jsonPath:'bets[?(@.name== "Exact Score")].values[?(@.value== "4:0" )].odd'},
    ODDS_CORRECT_SCORE_44: {id: "ODDS_CORRECT_SCORE_44", description: "CS(4-4)", jsonFieldId: "matchOdd", toolTip: "Exact Score 4-4", jsonPath:'bets[?(@.name== "Exact Score")].values[?(@.value== "4:4" )].odd'},
    ODDS_CORRECT_SCORE_50: {id: "ODDS_CORRECT_SCORE_50", description: "CS(5-0)", jsonFieldId: "matchOdd", toolTip: "Exact Score 5-0", jsonPath:'bets[?(@.name== "Exact Score")].values[?(@.value== "5:0" )].odd'},
    ODDS_CORRECT_SCORE_51: {id: "ODDS_CORRECT_SCORE_51", description: "CS(5-1)", jsonFieldId: "matchOdd", toolTip: "Exact Score 5-1", jsonPath:'bets[?(@.name== "Exact Score")].values[?(@.value== "5:1" )].odd'},

    ODDS_CORRECT_SCORE_FIRST_HALF_00: {id: "ODDS_CORRECT_SCORE_FIRST_HALF_00", description: "HT CS(0-0)", jsonFieldId: "matchOdd", toolTip: "First Half Score 0-0", jsonPath:'bets[?(@.name== "Correct Score - First Half")].values[?(@.value== "0:0" )].odd'},
    ODDS_CORRECT_SCORE_FIRST_HALF_01: {id: "ODDS_CORRECT_SCORE_FIRST_HALF_01", description: "HT CS(0-1)", jsonFieldId: "matchOdd", toolTip: "First Half Score 0-1", jsonPath:'bets[?(@.name== "Correct Score - First Half")].values[?(@.value== "0:1" )].odd'},
    ODDS_CORRECT_SCORE_FIRST_HALF_02: {id: "ODDS_CORRECT_SCORE_FIRST_HALF_02", description: "HT CS(0-2)", jsonFieldId: "matchOdd", toolTip: "First Half Score 0-2", jsonPath:'bets[?(@.name== "Correct Score - First Half")].values[?(@.value== "0:2" )].odd'},
    ODDS_CORRECT_SCORE_FIRST_HALF_03: {id: "ODDS_CORRECT_SCORE_FIRST_HALF_03", description: "HT CS(0-3)", jsonFieldId: "matchOdd", toolTip: "First Half Score 0-3", jsonPath:'bets[?(@.name== "Correct Score - First Half")].values[?(@.value== "0:3" )].odd'},
    ODDS_CORRECT_SCORE_FIRST_HALF_10: {id: "ODDS_CORRECT_SCORE_FIRST_HALF_10", description: "HT CS(1-0)", jsonFieldId: "matchOdd", toolTip: "First Half Score 1-0", jsonPath:'bets[?(@.name== "Correct Score - First Half")].values[?(@.value== "1:0" )].odd'},
    ODDS_CORRECT_SCORE_FIRST_HALF_11: {id: "ODDS_CORRECT_SCORE_FIRST_HALF_11", description: "HT CS(1-1)", jsonFieldId: "matchOdd", toolTip: "First Half Score 1-1", jsonPath:'bets[?(@.name== "Correct Score - First Half")].values[?(@.value== "1:1" )].odd'},
    ODDS_CORRECT_SCORE_FIRST_HALF_12: {id: "ODDS_CORRECT_SCORE_FIRST_HALF_12", description: "HT CS(1-2)", jsonFieldId: "matchOdd", toolTip: "First Half Score 1-2", jsonPath:'bets[?(@.name== "Correct Score - First Half")].values[?(@.value== "1:2" )].odd'},
    ODDS_CORRECT_SCORE_FIRST_HALF_13: {id: "ODDS_CORRECT_SCORE_FIRST_HALF_13", description: "HT CS(1-3)", jsonFieldId: "matchOdd", toolTip: "First Half Score 1-3", jsonPath:'bets[?(@.name== "Correct Score - First Half")].values[?(@.value== "1:3" )].odd'},
    ODDS_CORRECT_SCORE_FIRST_HALF_20: {id: "ODDS_CORRECT_SCORE_FIRST_HALF_20", description: "HT CS(2-0)", jsonFieldId: "matchOdd", toolTip: "First Half Score 2-0", jsonPath:'bets[?(@.name== "Correct Score - First Half")].values[?(@.value== "2:0" )].odd'},
    ODDS_CORRECT_SCORE_FIRST_HALF_21: {id: "ODDS_CORRECT_SCORE_FIRST_HALF_21", description: "HT CS(2-1)", jsonFieldId: "matchOdd", toolTip: "First Half Score 2-1", jsonPath:'bets[?(@.name== "Correct Score - First Half")].values[?(@.value== "2:1" )].odd'},
    ODDS_CORRECT_SCORE_FIRST_HALF_22: {id: "ODDS_CORRECT_SCORE_FIRST_HALF_22", description: "HT CS(2-2)", jsonFieldId: "matchOdd", toolTip: "First Half Score 2-2", jsonPath:'bets[?(@.name== "Correct Score - First Half")].values[?(@.value== "2:2" )].odd'},
    ODDS_CORRECT_SCORE_FIRST_HALF_23: {id: "ODDS_CORRECT_SCORE_FIRST_HALF_23", description: "HT CS(2-3)", jsonFieldId: "matchOdd", toolTip: "First Half Score 2-3", jsonPath:'bets[?(@.name== "Correct Score - First Half")].values[?(@.value== "2:3" )].odd'},
    ODDS_CORRECT_SCORE_FIRST_HALF_30: {id: "ODDS_CORRECT_SCORE_FIRST_HALF_30", description: "HT CS(3-0)", jsonFieldId: "matchOdd", toolTip: "First Half Score 3-0", jsonPath:'bets[?(@.name== "Correct Score - First Half")].values[?(@.value== "3:0" )].odd'},
    ODDS_CORRECT_SCORE_FIRST_HALF_31: {id: "ODDS_CORRECT_SCORE_FIRST_HALF_31", description: "HT CS(3-1)", jsonFieldId: "matchOdd", toolTip: "First Half Score 3-1", jsonPath:'bets[?(@.name== "Correct Score - First Half")].values[?(@.value== "3:1" )].odd'},
    ODDS_CORRECT_SCORE_FIRST_HALF_32: {id: "ODDS_CORRECT_SCORE_FIRST_HALF_32", description: "HT CS(3-2)", jsonFieldId: "matchOdd", toolTip: "First Half Score 3-2", jsonPath:'bets[?(@.name== "Correct Score - First Half")].values[?(@.value== "3:2" )].odd'},
    //ODDS_CORRECT_SCORE_FIRST_HALF_33: {id: "ODDS_CORRECT_SCORE_FIRST_HALF_33", description: "HT CS(3-3)", jsonFieldId: "matchOdd", toolTip: "First Half Score 3-3"},
    //ODDS_CORRECT_SCORE_FIRST_HALF_14: {id: "ODDS_CORRECT_SCORE_FIRST_HALF_14", description: "HT CS(1-4)", jsonFieldId: "matchOdd", toolTip: "First Half Score 1-4"},
    //ODDS_CORRECT_SCORE_FIRST_HALF_24: {id: "ODDS_CORRECT_SCORE_FIRST_HALF_24", description: "HT CS(2-4)", jsonFieldId: "matchOdd", toolTip: "First Half Score 2-4"},
    //ODDS_CORRECT_SCORE_FIRST_HALF_34: {id: "ODDS_CORRECT_SCORE_FIRST_HALF_34", description: "HT CS(3-4)", jsonFieldId: "matchOdd", toolTip: "First Half Score 3-4"},
    //ODDS_CORRECT_SCORE_FIRST_HALF_40: {id: "ODDS_CORRECT_SCORE_FIRST_HALF_40", description: "HT CS(4-0)", jsonFieldId: "matchOdd", toolTip: "First Half Score 4-0"},
    //ODDS_CORRECT_SCORE_FIRST_HALF_44: {id: "ODDS_CORRECT_SCORE_FIRST_HALF_44", description: "Half Score(44)", jsonFieldId: "matchOdd", toolTip: "First Half Score 4-4"},
    //ODDS_CORRECT_SCORE_FIRST_HALF_50: {id: "ODDS_CORRECT_SCORE_FIRST_HALF_50", description: "Half Score(50)", jsonFieldId: "matchOdd", toolTip: "First Half Score 5-0"},
    //ODDS_CORRECT_SCORE_FIRST_HALF_51: {id: "ODDS_CORRECT_SCORE_FIRST_HALF_51", description: "Half Score(51)", jsonFieldId: "matchOdd", toolTip: "First Half Score 5-1"},
    

    /*ODDS_CORRECT_SCORE_SECOND_HALF_00: {id: "ODDS_CORRECT_SCORE_SECOND_HALF_00", description: "2nd Half Score(00)", jsonFieldId: "matchOdd", toolTip: "Second Half Score 0-0"},
    ODDS_CORRECT_SCORE_SECOND_HALF_01: {id: "ODDS_CORRECT_SCORE_SECOND_HALF_01", description: "2nd Half Score(01)", jsonFieldId: "matchOdd", toolTip: "Second Half Score 0-1"},
    ODDS_CORRECT_SCORE_SECOND_HALF_02: {id: "ODDS_CORRECT_SCORE_SECOND_HALF_02", description: "2nd Half Score(02)", jsonFieldId: "matchOdd", toolTip: "Second Half Score 0-2"},
    ODDS_CORRECT_SCORE_SECOND_HALF_03: {id: "ODDS_CORRECT_SCORE_SECOND_HALF_03", description: "2nd Half Score(03)", jsonFieldId: "matchOdd", toolTip: "Second Half Score 0-3"},
    ODDS_CORRECT_SCORE_SECOND_HALF_10: {id: "ODDS_CORRECT_SCORE_SECOND_HALF_10", description: "2nd Half Score(10)", jsonFieldId: "matchOdd", toolTip: "Second Half Score 1-0"},
    ODDS_CORRECT_SCORE_SECOND_HALF_11: {id: "ODDS_CORRECT_SCORE_SECOND_HALF_11", description: "2nd Half Score(11)", jsonFieldId: "matchOdd", toolTip: "Second Half Score 1-1"},
    ODDS_CORRECT_SCORE_SECOND_HALF_12: {id: "ODDS_CORRECT_SCORE_SECOND_HALF_12", description: "2nd Half Score(12)", jsonFieldId: "matchOdd", toolTip: "Second Half Score 1-2"},
    ODDS_CORRECT_SCORE_SECOND_HALF_13: {id: "ODDS_CORRECT_SCORE_SECOND_HALF_13", description: "2nd Half Score(13)", jsonFieldId: "matchOdd", toolTip: "Second Half Score 1-3"},
    ODDS_CORRECT_SCORE_SECOND_HALF_20: {id: "ODDS_CORRECT_SCORE_SECOND_HALF_20", description: "2nd Half Score(20)", jsonFieldId: "matchOdd", toolTip: "Second Half Score 2-0"},
    ODDS_CORRECT_SCORE_SECOND_HALF_21: {id: "ODDS_CORRECT_SCORE_SECOND_HALF_21", description: "2nd Half Score(21)", jsonFieldId: "matchOdd", toolTip: "Second Half Score 2-1"},
    ODDS_CORRECT_SCORE_SECOND_HALF_22: {id: "ODDS_CORRECT_SCORE_SECOND_HALF_22", description: "2nd Half Score(22)", jsonFieldId: "matchOdd", toolTip: "Second Half Score 2-2"},
    ODDS_CORRECT_SCORE_SECOND_HALF_23: {id: "ODDS_CORRECT_SCORE_SECOND_HALF_23", description: "2nd Half Score(23)", jsonFieldId: "matchOdd", toolTip: "Second Half Score 2-3"},
    ODDS_CORRECT_SCORE_SECOND_HALF_30: {id: "ODDS_CORRECT_SCORE_SECOND_HALF_30", description: "2nd Half Score(30)", jsonFieldId: "matchOdd", toolTip: "Second Half Score 3-0"},
    ODDS_CORRECT_SCORE_SECOND_HALF_31: {id: "ODDS_CORRECT_SCORE_SECOND_HALF_31", description: "2nd Half Score(31)", jsonFieldId: "matchOdd", toolTip: "Second Half Score 3-1"},
    ODDS_CORRECT_SCORE_SECOND_HALF_32: {id: "ODDS_CORRECT_SCORE_SECOND_HALF_32", description: "2nd Half Score(32)", jsonFieldId: "matchOdd", toolTip: "Second Half Score 3-2"},
    ODDS_CORRECT_SCORE_SECOND_HALF_33: {id: "ODDS_CORRECT_SCORE_SECOND_HALF_33", description: "2nd Half Score(33)", jsonFieldId: "matchOdd", toolTip: "Second Half Score 3-3"},
    ODDS_CORRECT_SCORE_SECOND_HALF_14: {id: "ODDS_CORRECT_SCORE_SECOND_HALF_14", description: "2nd Half Score(14)", jsonFieldId: "matchOdd", toolTip: "Second Half Score 1-4"},
    ODDS_CORRECT_SCORE_SECOND_HALF_24: {id: "ODDS_CORRECT_SCORE_SECOND_HALF_24", description: "2nd Half Score(24)", jsonFieldId: "matchOdd", toolTip: "Second Half Score 2-4"},
    ODDS_CORRECT_SCORE_SECOND_HALF_34: {id: "ODDS_CORRECT_SCORE_SECOND_HALF_34", description: "2nd Half Score(34)", jsonFieldId: "matchOdd", toolTip: "Second Half Score 3-4"},
    ODDS_CORRECT_SCORE_SECOND_HALF_04: {id: "ODDS_CORRECT_SCORE_SECOND_HALF_04", description: "2nd Half Score(04)", jsonFieldId: "matchOdd", toolTip: "Second Half Score 0-4"},
    */
    //ODDS_CORRECT_SCORE_SECOND_HALF_44: {id: "ODDS_CORRECT_SCORE_SECOND_HALF_44", description: "2nd Half Score(44)", jsonFieldId: "matchOdd", toolTip: "2nd Half  Score 4-4"},
    //ODDS_CORRECT_SCORE_SECOND_HALF_50: {id: "ODDS_CORRECT_SCORE_SECOND_HALF_50", description: "2nd Half Score(50)", jsonFieldId: "matchOdd", toolTip: "2nd Half  Score 5-0"},
    //ODDS_CORRECT_SCORE_SECOND_HALF_51: {id: "ODDS_CORRECT_SCORE_SECOND_HALF_51", description: "2nd Half Score(51)", jsonFieldId: "matchOdd", toolTip: "2nd Half  Score 5-1"},
    
    
    ODDS_BTTS_YES: {id: "ODDS_BTTS_YES", description: "BTTS(Y)", jsonFieldId: "matchOdd", toolTip: "BTTS Yes", jsonPath:'bets[?(@.name== "Both Teams Score")].values[?(@.value== "Yes" )].odd'},
    ODDS_BTTS_NO: {id: "ODDS_BTTS_NO", description: "BTTS(N)", jsonFieldId: "matchOdd", toolTip: "BTTS No", jsonPath:'bets[?(@.name== "Both Teams Score")].values[?(@.value== "No" )].odd'},

    ODDS_DRAW_NB_HOME: {id: "ODDS_DRAW_NB_HOME", description: "DNB(H)", jsonFieldId: "matchOdd", toolTip: "Draw No Bet( Home)", jsonPath:'bets[?(@.name== "Home/Away")].values[?(@.value== "Home" )].odd'},
    ODDS_DRAW_NB_AWAY: {id: "ODDS_DRAW_NB_AWAY", description: "DNB(A)", jsonFieldId: "matchOdd", toolTip: "Draw No Bet( Away)", jsonPath:'bets[?(@.name== "Home/Away")].values[?(@.value== "Away" )].odd'},
    /*ODDS_DRAW_NB_FIRST_HALF_HOME: {id: "ODDS_DRAW_NB_FIRST_HALF_HOME", description: "DNB 1st(H)", jsonFieldId: "matchOdd", toolTip: "Draw No Bet First  Half( Home)"},
    ODDS_DRAW_NB_FIRST_HALF_AWAY: {id: "ODDS_DRAW_NB_FIRST_HALF_AWAY", description: "DNB 1st(A)", jsonFieldId: "matchOdd", toolTip: "Draw No Bet First  Half ( Away)"},
    ODDS_DRAW_NB_SECOND_HALF_HOME: {id: "ODDS_DRAW_NB_SECOND_HALF_HOME", description: "DNB 2nd(H)", jsonFieldId: "matchOdd", toolTip: "Draw No Bet Second Half ( Home)"},
    ODDS_DRAW_NB_SECOND_HALF_AWAY: {id: "ODDS_DRAW_NB_SECOND_HALF_AWAY", description: "DNB 2nd (A)", jsonFieldId: "matchOdd", toolTip: "Draw No Bet Second Half ( Away)"}
    */
}


export const MATCH_TABLE_COLUMNS_DYNAMIC = {
    ...MATCH_TABLE_COLUMNS_STATS_HOME,
    ...MATCH_TABLE_COLUMNS_STATS_AWAY,
    ...MATCH_TABLE_COLUMNS_RESULTS,
    ...MATCH_TABLE_COLUMNS_ODDS
};

export const MATCH_TABLE_COLUMNS_ALL = {
    ...MATCH_TABLE_COLUMNS_STATIC,
    ...MATCH_TABLE_COLUMNS_DYNAMIC
};

export const LOGIN_PAGE_FORM_FIELDS = {
    USERNAME_TEXT: {id: "USERNAME", key: "username", label: "Email address/Username", placeHolder: "Enter Email"},
    PASSWORD_TEXT: {id: "PASSWORD", key: "password", label: "Password", placeHolder: "Enter Password"},
    SAVE_PASSWORD_FLAG_CHECK_BOX: {id: "SAVE_PASSWORD_FLAG", key: "save-password-flag", label: "Save Password"},
    SIGN_IN_BUTTON: {id: "SIGN_INN", key: "sing-in-button", label: "Sign In"}
}

export const CHANGE_PASSWORD_FORM_FIELDS = {
    PASSWORD_TEXT: {id: "PASSWORD", key: "password", label: "Current Password", placeHolder: "Current Password"},
    PASSWORD_NEW_TEXT: {id: "PASSWORD_NEW", key: "password-new", label: "New Password", placeHolder: "New Password"},
    PASSWORD_NEW_VERIFY_TEXT: {id: "PASSWORD_NEW_VERIFY", key: "password-new-verify", label: "Confirm Password", placeHolder: "Confirm Password"},
    CHANGE_PASSWORD_BUTTON: {id: "CHANGE_PASSWORD", key: "change-password-button", label: "Change Password"}
}

export const FORGOT_PASSWORD_FORM_FIELDS = {
    USERNAME_TEXT: {id: "USERNAME", key: "username", label: "Email address/Username", placeHolder: "Enter Email"},
    VERIFICATION_CODE_TEXT: {id: "VERIFICATION_CODE", key: "verification-code", label: "Verification Code", placeHolder: "Enter Verification Code"},
    PASSWORD_NEW_TEXT: {id: "PASSWORD_NEW", key: "password-new", label: "New Password", placeHolder: "Enter New Password"},
    PASSWORD_NEW_VERIFY_TEXT: {id: "PASSWORD_NEW_VERIFY", key: "password-new-verify", label: "Confirm Password", placeHolder: "Confirm New Password"},
    REQUEST_VERIFICATION_CODE_BUTTON: {id: "REQUEST_VERIFICATION_CODE", key: "request-verification-code-button", label: "Request Verification Code"},
    SUBMIT_BUTTON: {id: "SUBMIT", key: "submit-button", label: "Submit"}
}

export const HEADER_CONTROLS = {
    TOGGLE_MODE_BUTTON: {id: "TOGGLE_MODE_BUTTON", key: "toggleModeButton", label0: "Leagues", label1: "Today Matches"},
    TOGGLE_HOME_BUTTON: {id: "TOGGLE_HOME_BUTTON", key: "toggleHomeButton", label0: "Home", label1: "Total"},
    TOGGLE_AWAY_BUTTON: {id: "TOGGLE_AWAY_BUTTON", key: "toggleAwayButton", label0: "Away", label1: "Total"},
    EXPORT_DATA_BUTTON: {id: "EXPORT_DATA_BUTTON", key: "toggleAwayButton", label: "Export Data"}
}

export const HEADER_BUTTONS = {
    JOCKEY: {id: "JOCKEY", key: "toggleModeButton", label: "LeJockey"},
}

export const TICKLER_TAB_BUTTON = {
    HOT_JOCKEY: {id: "HOT_JOCKEY", label: "Hot Jockey", urlPath: "jockey/", emptyMessage:"Please select race"},
    HOT_TRAINER: {id: "HOT_TRAINER", label: "Hot Trainer", urlPath: "trainer/", emptyMessage:"No hot trainer available today"},
    COURSE_DISTANCE: {id: "COURSE_DISTANCE", label: "Course Distance", urlPath: "courseanddistance/",  emptyMessage:"No horses running with Course & Distance Winner today"},
    LONGEST_TRAVELLER: {id: "LONGEST_TRAVELLER",  label: "Longest Traveller", urlPath: "longesttraveller/", emptyMessage:"No horses running with longest travellers today"},
    FIRST_TIME_VISORS: {id: "FIRST_TIME_VISORS", label: "First Time Visors", urlPath: "visitors/",  emptyMessage:"No horses running with first-time visors today"},
    FIRST_TIME_BLINKERS: {id: "FIRST_TIME_BLINKERS",  label: "First Time Blinker", urlPath: "blinker/",  emptyMessage:"No horses running with first-time blinkers today"},
    CHEEK_PIECE: {id: "CHEEK_PIECE",   label: "Cheek Piece", urlPath: "cheekpiece/",  emptyMessage:"No horses running with cheekpieces today"},
    TONGUE_STRAP: {id: "TONGUE_STRAP", label: "Tongue Strap", urlPath: "tonguestrap/",  emptyMessage:"No horses running with tonguestraps today"},
}

export const FILTER_HOME = {
    BACK_ODD: {id: "BACK_ODD", min: 0, max: 1000,step:1, defaultMin: 0, defaultMax :1000, title:"Back Odd", jsonFieldId:"runner.metadata.backOdds"},
   // ODD: {id: "ODD", min: 0, max: 1000,step:10, defaultMin: 0, defaultMax :1000, title:"Odd", jsonFieldId:"homeStat.goalsScored"},
    AGE: {id: "AGE", min: 0, max: 20,step:1, defaultMin: 0, defaultMax :20, title:"Age", jsonFieldId: "runner.metadata.AGE"},
    DAYS_SINCE_LAST_RUN: {id: "DAYS_SINCE_LAST_RUN", min: 0, max: 500,step:1, defaultMin: 0, defaultMax :500, title:"Days Since Run", jsonFieldId: "runner.metadata.DAYS_SINCE_LAST_RUN"},
}


export const MODE_HOT_JOCKEY = "jockey"
export const MODE_HOT_TRAINER = "trainer"



export const MODE_MATCHES = "match"
export const HOME = "home"
export const AWAY = "away"
export const TOTAL = "total"
