import { React, useState, useEffect } from 'react';
import { Image, Container, Row, Col } from 'react-bootstrap';
import { logInfo } from "../../../../util/log/logger"
import { connect } from "react-redux"
import classes from './style.module.css'
import { SET_FILTER, SET_SELECTION_STATE } from "../../../../redux/actions"
import MultiRangeSlider from "multi-range-slider-react";

const Filter = (props) => {
    const [matchDurationMin, setMatchDurationMin] = useState(0);
    const [matchDurationMax, setMatchDurationMax] = useState(90);

    const [market, setMarket] = useState('Market');

    const [selectionOdd, setSelectionOdd] = useState('Home');
    const [selectionUnderOver, setSelectionUnderOver] = useState('Under');
    const [selectionCorrectScore, setSelectionCorrectScore] = useState('0-0');

    const [minOddOdd, setMinOddOdd] = useState(0);
    const [maxOddOdd, setMaxOddOdd] = useState(999);
    const [minOddUnderOver, setMinOddUnderOver] = useState(0);
    const [maxOddUnderOver, setMaxOddUnderOver] = useState(999);

    const [minOddCorrectScore, setMinOddCorrectScore] = useState(0);
    const [maxOddCorrectScore, setMaxOddCorrectScore] = useState(999);

    const [minBtts, setMinBtts] = useState(0);
    const [maxBtts, setMaxBtts] = useState(100);

    const [minCS25, setMinCS25] = useState(0);
    const [maxCS25, setMaxCS25] = useState(100);

    const [minCombGS, setMinCombGS] = useState(0);
    const [maxCombGS, setMaxCombGS] = useState(10);

    const [homeMin, setHomeMin] = useState(0);
    const [homeMax, setHomeMax] = useState(100);

    const [awayMin, setAwayMin] = useState(0);
    const [awayMax, setAwayMax] = useState(100);



    const [oddVolume, setOddVolume] = useState(0);
    const [underOverVolumne, setUnderOverVolumne] = useState(0);
    const [correctScoreVolumne, setCorrectScoreVolumne] = useState(0);

    const [priceOdd, setPriceOdd] = useState('Back');
    const [priceUnderOver, setPriceUnderOver] = useState('Back');
    const [priceCorrectScore, setPriceCorrectScore] = useState('Back');

    const bannerImageClicked = url => {
        logInfo("bannerImageClicked. url: " + url)
    }

    let banner = <a href={props.desturl} target="_blank"><Image src={props.image} fluid onClick={() => bannerImageClicked(props.desturl)} /></a>

    const handleClick = () => {
        const filterCriteria = {
            "market": market,
            "selectionOdd": selectionOdd,
            "selectionUnderOver": selectionUnderOver,
            "selectionCorrectScore": selectionCorrectScore,
            "minOddOdd": minOddOdd,
            "maxOddOdd": maxOddOdd,
            "minOddUnderOver": minOddUnderOver,
            "maxOddUnderOver": maxOddUnderOver,
            "minOddCorrectScore": minOddCorrectScore,
            "maxOddCorrectScore": maxOddCorrectScore,
            "priceOdd": priceOdd,
            "priceUnderOver": priceUnderOver,
            "priceCorrectScore": priceCorrectScore,
            "matchDurationMin": matchDurationMin,
            "matchDurationMax": matchDurationMax,
            "oddVolume": oddVolume,
            "underOverVolumne": underOverVolumne,
            "correctScoreVolumne": correctScoreVolumne,
            "minBtts": minBtts,
            "maxBtts": maxBtts,
            "minCS25": minCS25,
            "maxCS25": maxCS25,
            "minCombGS": minCombGS,
            "maxCombGS": maxCombGS,
            "homeMin": homeMin,
            "homeMax": homeMax,
            "awayMin": awayMin,
            "awayMax": awayMax
        };
        props.setFilterCriteria(filterCriteria);
        localStorage.setItem('filters', JSON.stringify(filterCriteria));
    }

    const handleSavedClick = () => {
        const filterCriteria = JSON.parse(localStorage.getItem('filters'));
        setMarket(filterCriteria.market);
        setSelectionOdd(filterCriteria.selectionOdd);
        setSelectionUnderOver(filterCriteria.selectionUnderOver);
        setSelectionCorrectScore(filterCriteria.selectionCorrectScore);
        setMinOddOdd(filterCriteria.minOddOdd);
        setMaxOddOdd(filterCriteria.maxOddOdd);
        setMinOddUnderOver(filterCriteria.minOddUnderOver);
        setMaxOddUnderOver(filterCriteria.maxOddUnderOver);
        setMinOddCorrectScore(filterCriteria.minOddCorrectScore);
        setMaxOddCorrectScore(filterCriteria.maxOddCorrectScore);
        setPriceOdd(filterCriteria.priceOdd);
        setPriceUnderOver(filterCriteria.priceUnderOver);
        setPriceCorrectScore(filterCriteria.priceCorrectScore);
        setMatchDurationMin(filterCriteria.matchDurationMin);
        setMatchDurationMax(filterCriteria.matchDurationMax);
        setOddVolume(filterCriteria.oddVolume);
        setUnderOverVolumne(filterCriteria.underOverVolumne);
        setCorrectScoreVolumne(filterCriteria.correctScoreVolumne);
        setMinBtts(filterCriteria.minBtts);
        setMaxBtts(filterCriteria.maxBtts);
        setMinCS25(filterCriteria.minCS25);
        setMaxCS25(filterCriteria.maxCS25);
        setMinCombGS(filterCriteria.minCombGS);
        setMaxCombGS(filterCriteria.maxCombGS);
        setHomeMin(filterCriteria.homeMin);
        setHomeMax(filterCriteria.homeMax);
        setAwayMin(filterCriteria.awayMin);
        setAwayMax(filterCriteria.awayMax);
        props.setFilterCriteria(filterCriteria);
    }

    const handleResetClick = () => {
        setSelectionOdd('Home')
        setSelectionUnderOver('Under')
        setSelectionCorrectScore('0-0')

        setPriceOdd('Back')
        setPriceUnderOver('Back')
        setPriceCorrectScore('Back')

        setMinOddOdd(0)
        setMinOddUnderOver(0)
        setMinOddCorrectScore(0)

        setMaxOddOdd(999)
        setMaxOddUnderOver(999)
        setMaxOddCorrectScore(999)

        setOddVolume(0)
        setUnderOverVolumne(0)
        setCorrectScoreVolumne(0)

        setMatchDurationMin(0)
        setMatchDurationMax(90)
        setMinBtts(0)
        setMinCS25(0)
        setMinCombGS(0)

        setMaxBtts(100)
        setMaxCS25(100)
        setMaxCombGS(100)
        setHomeMin(0);
        setHomeMax(100);
        setAwayMin(0);
        setAwayMax(100);
        
        localStorage.removeItem('filters');
        props.setFilterCriteria({
        });
    }

    useEffect(() => {
        setSelectionOdd('Home')
        setSelectionUnderOver('Under')
        setSelectionCorrectScore('0-0')

        setPriceOdd('Back')
        setPriceUnderOver('Back')
        setPriceCorrectScore('Back')

        setMinOddOdd(0)
        setMinOddUnderOver(0)
        setMinOddCorrectScore(0)

        setMaxOddOdd(999)
        setMaxOddUnderOver(999)
        setMaxOddCorrectScore(999)

        setOddVolume(0)
        setUnderOverVolumne(0)
        setCorrectScoreVolumne(0)

        setMatchDurationMin(0)
        setMatchDurationMax(90)
        setMinBtts(0)
        setMinCS25(0)
        setMinCombGS(0)

        setMaxBtts(100)
        setMaxCS25(100)
        setMaxCombGS(10)

        setHomeMin(0)
        setHomeMax(100)
        setAwayMin(0)
        setAwayMax(100)
        
        
        props.setFilterCriteria({
        });
      //  setFilterButtonDisabled(true);
      //  setTimeout(() => setFilterButtonDisabled(false), 5000);
    }, [props.selectionState.selectionDate])

    const handleMatchDuration = (e) => {
        setMatchDurationMin(e.minValue);
        setMatchDurationMax(e.maxValue);
    }

    const handleSelectPriceOdd = (e) => {
        setPriceOdd(e.target.value)
    }
    const handleSelectPriceUnderOver = (e) => {
        setPriceUnderOver(e.target.value)
    }
    const handleSelectPriceCorrectScore = (e) => {
        setPriceCorrectScore(e.target.value)
    }
    const handleSelectSelectionOdd = (e) => {
        setSelectionOdd(e.target.value)
    }
    const handleSelectSelectionUnderOver = (e) => {
        setSelectionUnderOver(e.target.value)
    }
    const handleSelectSelectionCorrectScore = (e) => {
        setSelectionCorrectScore(e.target.value)
    }
    const handleMinOddOdd = (e) => {
        setMinOddOdd(e.target.value)
    }
    const handleMinOddUnderOver = (e) => {
        setMinOddUnderOver(e.target.value)
    }
    const handleMinOddCorrectScore = (e) => {
        setMinOddCorrectScore(e.target.value)
    }

    const handleOddVolume = (e) => {
        setOddVolume(e.target.value)
    }

    const handleUnderOverVolume = (e) => {
        setUnderOverVolumne(e.target.value)
    }
    const handleCorrectScoreVolume = (e) => {
        setCorrectScoreVolumne(e.target.value)
    }

    const handleMaxOddOdd = (e) => {
        setMaxOddOdd(e.target.value)
    }
    const handleMaxOddUnderOver = (e) => {
        setMaxOddUnderOver(e.target.value)
    }
    const handleMaxOddCorrectScore = (e) => {
        setMaxOddCorrectScore(e.target.value)
    }

    const handleHomeMin = (e) => {
        setHomeMin(e.target.value);
    }

    const handleHomeMax = (e) => {
        setHomeMax(e.target.value);
    }
    const handleAwayMin = (e) => {
        setAwayMin(e.target.value);
    }

    const handleAwayMax = (e) => {
        setAwayMax(e.target.value);
    }

    const handleBTTSMin = (e) => {
        setMinBtts(e.target.value);
    }

    const handleBTTSMax = (e) => {
        setMaxBtts(e.target.value);
    }

    const handleCombGSMin = (e) => {
        setMinCombGS(e.target.value);
    }

    const handleCombGSMax = (e) => {
        setMaxCombGS(e.target.value);
    }

    const handleCS25Min = (e) => {
        setMinCS25(e.target.value);
    }

    const handleCS25Max = (e) => {
        setMaxCS25(e.target.value);
    }
    //grey out if any filter is saved.
    const isFilterSaved = () => {
        return localStorage.getItem('filters') != null;
    }
    const options = ['0-0', '0-1', '0-2', '0-3',
        '1-0', '1-1', '1-2', '1-3',
        '2-0', '2-1', '2-2', '2-3',
        '3-0', '3-1', '3-2', '3-3',
        'Other Home Win', 'Other Away Win', 'Other Draw Win',];
    const optionsSelect = options.map((anOption, index) => <option value={anOption} >{anOption}</option>);

    //Enable filter button, hopefully all odds will be loaded.
    //setTimeout(() => setFilterButtonDisabled(false), 5000);

    return (
        <Container>
            <Row noGutters>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    Market
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    Selection
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    Price
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    Min
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    Max
                </Col>

                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    Volume
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>

                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    Min
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    Max
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>

                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    Min
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    Max
                </Col>


            </Row>
            <Row noGutters>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    Match Odds
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    <select value={selectionOdd} onChange={handleSelectSelectionOdd}
                        style={{ width: "85px", marginTop: "-10px", borderRadius: "5px", fontSize: "10px" }} name="selection" id="selectionOdd">
                        <option value="Selection">Selection</option>
                        <option value="Home">Home</option>
                        <option value="Draw">Draw</option>
                        <option value="Away">Away</option>
                    </select>
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    <select value={priceOdd} onChange={handleSelectPriceOdd} style={{ width: "85px", height: "20px", borderRadius: "5px", fontSize: "10px" }} name="price" id="price">
                        <option value="Price">Price</option>
                        <option value="Back">Back</option>
                        <option value="Lay">Lay</option>
                    </select>
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    <input value={minOddOdd} onChange={handleMinOddOdd} style={{ height: "20px", borderRadius: "5px", fontSize: "10px" }} type="number" id="minOdd" name="minOdd" min="0" max="9999" />
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    <input value={maxOddOdd} onChange={handleMaxOddOdd} style={{ height: "20px", borderRadius: "5px", fontSize: "10px" }} type="number" id="maxOdd" name="maxOdd" min="0" max="9999" />
                </Col>

                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    <input value={oddVolume} onChange={handleOddVolume} style={{ height: "20px", width: "85px", borderRadius: "5px", fontSize: "10px" }} type="number" min="0" max="999999" />
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    Comb GS+GC
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    <input value={minCombGS} onChange={handleCombGSMin} style={{ height: "20px", width: "85px", borderRadius: "5px", fontSize: "10px" }} type="number" min="0" max="999999" />
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    <input value={maxCombGS} onChange={handleCombGSMax}
                        style={{ height: "20px", width: "85px", borderRadius: "5px", fontSize: "10px" }}
                        type="number" min="0" max="10" />
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    Home Matches
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    <input value={homeMin} onChange={handleHomeMin} style={{ height: "20px", width: "85px", borderRadius: "5px", fontSize: "10px" }} type="number" min="0" max="999999" />
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    <input value={homeMax} onChange={handleHomeMax}
                        style={{ height: "20px", width: "85px", borderRadius: "5px", fontSize: "10px" }}
                        type="number" min="0" max="10" />
                </Col>
            </Row>
            <Row noGutters>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    Under Over 2.5
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    <select value={selectionUnderOver} onChange={handleSelectSelectionUnderOver} style={{ width: "85px", height: "20px", borderRadius: "5px", fontSize: "10px" }} name="selection" id="selection">
                        <option value="Selection">Selection</option>
                        <option value="Under">Under</option>
                        <option value="Over">Over</option>
                    </select>
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    <select value={priceUnderOver} onChange={handleSelectPriceUnderOver} style={{ width: "85px", height: "20px", borderRadius: "5px", fontSize: "10px" }} name="price" id="price">
                        <option value="Price">Price</option>
                        <option value="Back">Back</option>
                        <option value="Lay">Lay</option>
                    </select>
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    <input value={minOddUnderOver} onChange={handleMinOddUnderOver} style={{ height: "20px", borderRadius: "5px", fontSize: "10px" }} type="number" id="minOdd" name="minOdd" min="0" max="9999" />
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    <input value={maxOddUnderOver} onChange={handleMaxOddUnderOver} style={{ height: "20px", borderRadius: "5px", fontSize: "10px" }} type="number" id="maxOdd" name="maxOdd" min="0" max="9999" />
                </Col>

                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    <input value={underOverVolumne} onChange={handleUnderOverVolume} style={{ height: "20px", width: "85px", borderRadius: "5px", fontSize: "10px" }} type="number" min="0" max="999999" />
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    CO2.5
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    <input value={minCS25} onChange={handleCS25Min} style={{ height: "20px", width: "85px", borderRadius: "5px", fontSize: "10px" }} type="number" min="0" max="999999" />
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    <input value={maxCS25} onChange={handleCS25Max}
                        style={{ height: "20px", width: "85px", borderRadius: "5px", fontSize: "10px" }}
                        type="number" min="0" max="100" />
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    Away Matches
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    <input value={awayMin} onChange={handleAwayMin} style={{ height: "20px", width: "85px", borderRadius: "5px", fontSize: "10px" }} type="number" min="0" max="999999" />
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    <input value={awayMax} onChange={handleAwayMax}
                        style={{ height: "20px", width: "85px", borderRadius: "5px", fontSize: "10px" }}
                        type="number" min="0" max="10" />
                </Col>

            </Row>
            <Row noGutters>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    Correct Score
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    <select value={selectionCorrectScore} onChange={handleSelectSelectionCorrectScore}
                        style={{ width: "85px", height: "20px", borderRadius: "5px", fontSize: "10px" }} name="selection" id="selection">
                        {optionsSelect}
                    </select>
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    <select value={priceCorrectScore} onChange={handleSelectPriceCorrectScore} style={{ width: "85px", height: "20px", borderRadius: "5px", fontSize: "10px" }} name="price" id="price">
                        <option value="Price">Price</option>
                        <option value="Back">Back</option>
                        <option value="Lay">Lay</option>
                    </select>
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    <input value={minOddCorrectScore} onChange={handleMinOddCorrectScore} style={{ height: "20px", borderRadius: "5px", fontSize: "10px" }} type="number" id="minOdd" name="minOdd" min="0" max="9999" />
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    <input value={maxOddCorrectScore} onChange={handleMaxOddCorrectScore} style={{ height: "20px", borderRadius: "5px", fontSize: "10px" }} type="number" id="maxOdd" name="maxOdd" min="0" max="9999" />
                </Col>

                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    <input value={correctScoreVolumne} onChange={handleCorrectScoreVolume} style={{ height: "20px", width: "85px", borderRadius: "5px", fontSize: "10px" }} type="number" min="0" max="999999" />
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    BTTS
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    <input value={minBtts} onChange={handleBTTSMin} style={{ height: "20px", width: "85px", borderRadius: "5px", fontSize: "10px" }} type="number" min="0" max="999999" />
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    <input value={maxBtts} onChange={handleBTTSMax}
                        style={{ height: "20px", width: "85px", borderRadius: "5px", fontSize: "10px" }}
                        type="number" min="0" max="100" />
                </Col>
            </Row>
            <Row style={{ marginTop: "3px" }} noGutters>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    <input onClick={handleClick} style={{  marginLeft:"0px",fontWeight: "bold", height: "20px", width: "96px", borderRadius: "5px", fontSize: "10px" }} type="submit" value="Apply/Save Filter" />
                </Col>
                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    {
                        isFilterSaved() ?
                            <input onClick={handleSavedClick} style={{  marginLeft:"6px",fontWeight: "bold", height: "20px", width: "104px", borderRadius: "5px", fontSize: "10px", backgroundColor: 'green' }} type="submit" value="Retrieve Last Filter" />
                            :
                            <input onClick={handleSavedClick} style={{  marginLeft:"6px",fontWeight: "bold", height: "20px", width: "104px", borderRadius: "5px", fontSize: "10px" }} type="submit" value="Retrieve Last Filter" />
                    }
                </Col>

                <Col xs={1} style={{ fontSize: "10px", fontWeight: "bold" }}>
                    <input onClick={handleResetClick} style={{ marginLeft:"20px", fontWeight: "bold", height: "20px", width: "85px", borderRadius: "5px", fontSize: "10px" }} type="submit" value="Reset Filter" />
                </Col>
                <Col xs={1} style={{ marginLeft:"8px", fontSize: "10px", fontWeight: "bold", marginTop: "4px" }} >
                    Inplay({matchDurationMin} till {matchDurationMax}):
                </Col>
                <Col xs={5} style={{ marginTop: "6px", padding: "1px" }}>
                    <MultiRangeSlider
                        min={0}
                        max={90}
                        step={1}
                        ruler={false}
                        label={false}
                        preventWheel={false}
                        minValue={matchDurationMin}
                        maxValue={matchDurationMax}
                        onInput={(e) => {
                            handleMatchDuration(e);
                        }}
                    />

                </Col>

            </Row>
        </Container>
    )
}



const mapStateToProps = state => {
    return {
        filterCriteria: state.filterCriteria,
        selectionState: state.selectionState,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setFilterCriteria: filterCriteria =>
            dispatch({ type: SET_FILTER, payload: filterCriteria }),
        setSelectionState: payload =>
            dispatch({ type: SET_SELECTION_STATE, payload: payload }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Filter);