import React from 'react';
import classes from './style.module.css'
import { Button } from 'react-bootstrap';
import { connect } from "react-redux"
import { SET_SELECTION_STATE, SET_SOCCER_LEAGUES_STATE, SET_FILTER } from "../../redux/actions"


const ExportData = props => {

    const exportButtonClickedHandler = () => {
        let raceDate = props.selectionDate
        //console.log("*************" + props.selectionState.home);
        //console.log("*************" + props.selectionState.away);
        const homeOrTotal = (props.selectionState.home === 'home' ? true : false); 
        const awayOrTotal = (props.selectionState.away === 'away' ? true : false); 
        window.location.href = "https://spmapinonprem.makebettinggreat.com:8443/v1/export/all/" + raceDate
        + "?homeOrTotal=" + homeOrTotal + "&awayOrTotal=" + awayOrTotal;
        
    }
    return (
        <div className={classes.ExportData}>
            <Button size="sm" variant="dark" onClick={exportButtonClickedHandler}>{props.label}</Button>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        soccerLeaguesState: state.soccerLeaguesState,
        selectionState: state.selectionState,
        filterCriteria: state.filterCriteria,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setSoccerLeaguesState: payload =>
            dispatch({ type: SET_SOCCER_LEAGUES_STATE, payload: payload }),
        setSelectionState: payload =>
            dispatch({ type: SET_SELECTION_STATE, payload: payload }),
        setFilterCriteria: payload =>
            dispatch({ type: SET_FILTER, payload: payload }),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ExportData);