import React, { useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import classes from './style.module.css';
import { connect } from "react-redux"
import Modal from '../../components/UI/Modal'
import { SET_MODAL_STATE } from "../../redux/actions"
import { SET_LOGIN_STATE } from "../../redux/actions"
import { logout } from "../../util/login/application-login-utils"
import BannerRow from "../BannerRow"
import HeaderRow from "../HeaderRow"
import LeagueTable from "../../components/LeagueTable"
import headerImage from '../../assets/images/header.png'

const Dashboard = props => {
    const [matchFilter, setMatchFilter] = useState([]);

    const matchFilterHandler = (filter) => {
        setMatchFilter(filter);
    }
    const modalClosedHandler = () => {
        const modalState = { ...props.modalState }
        modalState.show = false;
        modalState.children = null;
        props.setModalState(modalState)
    }

    const logoutHandler = async () => {
        await logout();
        const loginState = { ...props.loginState }
        loginState.isLoggedIn = false;
        props.setLoginState(loginState);
    }


    if (!props.loginState.isLoggedIn) {
      return <Redirect to="/login" />
    }
    let content = <LeagueTable />
    let dashboard = (
        <Container fluid className={classes.Dashboard}>
            <BannerRow bannerImage={headerImage} />
            <HeaderRow active={true} matchFilterHandler={matchFilterHandler} logoutClicked={logoutHandler} />
            <Row className={classes.MiddleRow} noGutters >

                {content}
            </Row>
        </Container>
    )

    let modal = null;
    if (props.modalState.show) {
        modal = (
            <Modal show={props.modalState.show}
                modalClosed={modalClosedHandler}
                modalSubmit={() => props.modalState.onSubmit(modalClosedHandler)}
            >
                <p>Model is Being Displayed</p>
            </Modal>
        )
    } else {
        modal = null
    }

    return (
        <>
            {modal}
            {dashboard}
        </>

    )
}

const mapStateToProps = (state, ownProps) => {
    const returnProps = {
        loginState: state.loginState,
        modalState: state.modalState,
        selectionState: state.selectionState,
        ...ownProps
    }
    return returnProps
}

const mapDispatchToProps = dispatch => {
    return {
        setModalState: payload =>
            dispatch({ type: SET_MODAL_STATE, payload: payload }),
        setLoginState: payload =>
            dispatch({ type: SET_LOGIN_STATE, payload: payload }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);