import React, { useState, useEffect } from 'react';
import { Button, Modal, Table } from 'react-bootstrap'
import { getMarketOddCurrent } from '../../../../service/backend-api-service';
import { getCurrentScore } from '../../../../service/backend-api-service';
import classes from './style.module.css'

const OddResultCell = (props) => {
    const [currentOdd, setCurrentOdd] = useState(null)
    const [matchStatus, setMatchStatus] = useState({})
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const fetchValue = (value) => {
        return (value) ? Math.round(value * 10) / 10 : "N/A    ";
    }

    const isMatchInPlay = () =>{
            return matchStatus?.status?.longName === 'First Half' ||
            matchStatus?.status?.longName === 'Halftime' ||
            matchStatus?.status?.longName === 'Second Half';
    }

    const getModal = () => {
        let oddToConsider = currentOdd;
        
        return (<Modal style={{ height: "580px", marginTop: "-160px", fontSize: "15px" }} show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                {props.home} Vs. {props.away} Time: 
                {props.type === 'start' ? 'T 0 ' : ""}
                {props.type === 'ht' ? 'T H ' : ""}
                {props.type === 'current' ? 'T C ' :""}
            </Modal.Header>
            <Modal.Body style={{ fontSize: "12px" }}>
                <Table striped bordered responsive hover size="sm">
                    <thead>
                        <tr>
                            <th>Selection</th>
                            <th className={classes.BackPrice}>Back</th>
                            <th className={classes.LayPrice}>Lay</th>

                            <th>Selection</th>
                            <th className={classes.BackPrice}>Back</th>
                            <th className={classes.LayPrice}>Lay</th>
                            <th>Selection</th>
                            <th className={classes.BackPrice}>Back</th>
                            <th className={classes.LayPrice}>Lay</th>
                            <th>Selection</th>
                            <th>Back</th>
                            <th>Lay</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>0-0</td>
                            <td className={classes.BackPrice}>{oddToConsider.ftCorrectScoreZeroZeroBack}</td>
                            <td className={classes.LayPrice}>{oddToConsider.ftCorrectScoreZeroZeroLay}</td>
                            <td>1-0</td>
                            <td className={classes.BackPrice} >{oddToConsider.ftCorrectScoreOneZeroBack}</td>
                            <td className={classes.LayPrice}>{oddToConsider.ftCorrectScoreOneZeroLay}</td>
                            <td>2-0</td>
                            <td className={classes.BackPrice}>{oddToConsider.ftCorrectScoreTwoZeroBack}</td>
                            <td className={classes.LayPrice}>{oddToConsider.ftCorrectScoreTwoZeroLay}</td>
                            <td>3-0</td>
                            <td className={classes.BackPrice}>{oddToConsider.ftCorrectScoreThreeZeroBack}</td>
                            <td className={classes.LayPrice}>{oddToConsider.ftCorrectScoreThreeZeroLay}</td>
                        </tr>
                        <tr>
                            <td>0-1</td>
                            <td className={classes.BackPrice}>{oddToConsider.ftCorrectScoreZeroOneBack}</td>
                            <td className={classes.LayPrice}>{oddToConsider.ftCorrectScoreZeroOneLay}</td>
                            <td>1-1</td>
                            <td className={classes.BackPrice}>{oddToConsider.ftCorrectScoreOneOneBack}</td>
                            <td className={classes.LayPrice}>{oddToConsider.ftCorrectScoreOneOneLay}</td>
                            <td>2-1</td>
                            <td className={classes.BackPrice}>{oddToConsider.ftCorrectScoreTwoOneBack}</td>
                            <td className={classes.LayPrice}>{oddToConsider.ftCorrectScoreTwoOneLay}</td>
                            <td>3-1</td>
                            <td className={classes.BackPrice}>{oddToConsider.ftCorrectScoreThreeOneBack}</td>
                            <td className={classes.LayPrice}>{oddToConsider.ftCorrectScoreThreeOneLay}</td>
                        </tr>
                        <tr>
                            <td>0-2</td>
                            <td className={classes.BackPrice}>{oddToConsider.ftCorrectScoreZeroTwoBack}</td>
                            <td className={classes.LayPrice}>{oddToConsider.ftCorrectScoreZeroTwoLay}</td>
                            <td>1-2</td>
                            <td className={classes.BackPrice}>{oddToConsider.ftCorrectScoreOneTwoBack}</td>
                            <td className={classes.LayPrice}>{oddToConsider.ftCorrectScoreOneTwoLay}</td>
                            <td>2-2</td>
                            <td className={classes.BackPrice}>{oddToConsider.ftCorrectScoreTwoTwoBack}</td>
                            <td className={classes.LayPrice}>{oddToConsider.ftCorrectScoreTwoTwoLay}</td>
                            <td>3-2</td>
                            <td className={classes.BackPrice}>{oddToConsider.ftCorrectScoreThreeTwoBack}</td>
                            <td className={classes.LayPrice}>{oddToConsider.ftCorrectScoreThreeTwoLay}</td>
                        </tr>
                        <tr>
                            <td>0-3</td>
                            <td className={classes.BackPrice} >{oddToConsider.ftCorrectScoreZeroThreeBack}</td>
                            <td className={classes.LayPrice}>{oddToConsider.ftCorrectScoreZeroThreeLay}</td>
                            <td>1-3</td>
                            <td className={classes.BackPrice}>{oddToConsider.ftCorrectScoreOneThreeBack}</td>
                            <td className={classes.LayPrice}>{oddToConsider.ftCorrectScoreOneThreeLay}</td>
                            <td >2-3</td>
                            <td className={classes.BackPrice}>{oddToConsider.ftCorrectScoreTwoThreeBack}</td>
                            <td className={classes.LayPrice}>{oddToConsider.ftCorrectScoreTwoThreeLay}</td>
                            <td>3-3</td>
                            <td className={classes.BackPrice}>{oddToConsider.ftCorrectScoreThreeThreeBack}</td>
                            <td className={classes.LayPrice}>{oddToConsider.ftCorrectScoreThreeThreeLay}</td>
                        </tr>
                        <tr>
                            <td>Other Home Win</td>
                            <td className={classes.BackPrice}>{oddToConsider.ftCorrectScoreAoHwBack}</td>
                            <td className={classes.LayPrice}>{oddToConsider.ftCorrectScoreAoHwLay}</td>
                            <td>Other Away Win</td>
                            <td className={classes.BackPrice}>{oddToConsider.ftCorrectScoreAoAwBack}</td>
                            <td className={classes.LayPrice}>{oddToConsider.ftCorrectScoreAoAwLay}</td>
                            <td>Other Draw Win</td>
                            <td className={classes.BackPrice}>{oddToConsider.ftCorrectScoreAoDBack}</td>
                            <td className={classes.LayPrice}>{oddToConsider.ftCorrectScoreAoDLay}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>);
    }

    const isMatchFinished = (score) =>{
        return (score && score.status && score.status.elapsed > 84);
    }

    useEffect(() => {
        const fetchData = async () => {
            let eventId = props.eventId;
            let type = props.type;
            let status = await props.status;
            setMatchStatus(status)
            if(((status?.status?.longName === 'Match Finished'
            || status?.status?.longName === 'Finished' 
            || isMatchFinished(status) 
            || status?.status?.longName === 'Match Postponed'
            || status?.status?.longName === 'Match Cancelled') 
            && (props.type === 'ht' || props.type === 'current')) 
            || (status?.status?.longName === 'Not Started' && props.type === 'start')
            || ((!status || !status.status) && props.type === 'start')){
               //dont do anything.
            }else{
                let odds = await getMarketOddCurrent(eventId, type)
                setCurrentOdd(odds)
                props.oddChangeHandler(eventId, type, odds)
            }
           
        }
        fetchData();
        const interval = setInterval(() => { fetchData() }, 1 * 1000 * 60 * 5);
        return () => clearInterval(interval);
    }, [props.eventId, props.date, props.type])


    const round = (num) => {
        if (num !== null && num != 'undefined') {
            return (Math.round(num * 10) / 10) + "K"
        }
        return "";
    }
    const modal = currentOdd ? getModal() : null;
    let validOdds = currentOdd ? (
        <div style={{ width: "450px" }}>
            <div style={{ width: "196px", float: "left", margin: "5px", marginTop: "0px" }}>
                {props.type === 'start' ? 'T 0 ' :
                    ""}
                {props.type === 'ht' ? 'T H ' :
                    ""}
                {props.type === 'current' ? 'T C ' :
                    ""}
                <select style={{ borderRadius: "5px", backgroundColor: "#74BBEF", margin: "1px" }} name="home" id="home">
                    <option className={classes.BackPrice} value={fetchValue(currentOdd.matchOddsHomeBack)}>{fetchValue(currentOdd.matchOddsHomeBack)}</option>
                    <option className={classes.LayPrice} value={fetchValue(currentOdd.matchOddsHomeLay)}>{fetchValue(currentOdd.matchOddsHomeLay)}</option>
                </select>
                <select style={{ borderRadius: "5px", backgroundColor: "#74BBEF", margin: "1px" }} name="draw" id="draw">
                    <option className={classes.BackPrice} value={fetchValue(currentOdd.matchOddsHomeBack)}>{fetchValue(currentOdd.matchOddsDrawBack)}</option>
                    <option className={classes.LayPrice} value={fetchValue(currentOdd.matchOddsHomeLay)}>{fetchValue(currentOdd.matchOddsDrawLay)}</option>
                </select>
                
                <select style={{ borderRadius: "5px", backgroundColor: "#74BBEF", margin: "1px" }} name="away" id="away">
                    <option className={classes.BackPrice} value={fetchValue(currentOdd.matchOddsHomeBack)}>{fetchValue(currentOdd.matchOddsAwayBack)}</option>
                    <option className={classes.LayPrice} value={fetchValue(currentOdd.matchOddsHomeLay)}>{fetchValue(currentOdd.matchOddsAwayLay)}</option>
                </select>
                <div style={{ float: "right", marginTop: "0px" }}>
                    {round(currentOdd.matchOddsVolume / 1000.0)}
                </div>
            </div>
            <div style={{ width: "134px", float: "left", margin: "5px", marginTop: "0px" }}>
                <select style={{ borderRadius: "5px", backgroundColor: "#74BBEF", margin: "1px" }} name="home" id="home">
                    <option style={{ borderRadius: "5px", backgroundColor: "#74BBEF" }} value={fetchValue(currentOdd.underTwoPFiveBack)}>{fetchValue(currentOdd.underTwoPFiveBack)}</option>
                    <option className={classes.LayPrice} value={fetchValue(currentOdd.underTwoPFiveLay)}>{fetchValue(currentOdd.underTwoPFiveLay)}</option>
                </select>
                <select style={{ borderRadius: "5px", backgroundColor: "#74BBEF", margin: "1px" }} name="away" id="away">
                    <option style={{ borderRadius: "5px", backgroundColor: "#74BBEF" }} value={fetchValue(currentOdd.overTwoPFiveBack)}>{fetchValue(currentOdd.overTwoPFiveBack)}</option>
                    <option className={classes.LayPrice} value={fetchValue(currentOdd.overTwoPFiveLay)}>{fetchValue(currentOdd.overTwoPFiveLay)}</option>
                </select>
                <div style={{ float: "right", marginTop: "0px" }}>
                    {round(currentOdd.overUnderTwoPFiveVolume / 1000.0)}
                </div>
            </div>
            <div style={{ float: "left", margin: "5px", marginTop: "0px" }}>
                <div style={{ float: "left", textDecoration: "underline" }} variant="primary" size="xs" onClick={handleShow}>
                    Score
                </div>
                {modal}
                <div style={{ marginLeft: "5px", float: "right" }}>
                    {round(currentOdd.ftCorrectScoreVolume / 1000.0)}
                </div>
            </div>
        </div>) : null;
        //Supress ht and current for Match Finished and Match Postponed.
        //supress T0 for not started match
    return (
        <>

            {validOdds}
        </>
    )
}

export default OddResultCell;