import { React } from 'react';
import { Image, Row, Col } from 'react-bootstrap';
import classes from './style.module.css'
import { logInfo } from "../../../util/log/logger"
import { connect } from "react-redux"
import { SET_FILTER } from "../../../redux/actions"
import Filter from './filter';

const isDashboard = () =>{
    return window.location.pathname === '/dashboard';
}
const Banner = (props) => {
    const bannerImageClicked = url => {
        logInfo("bannerImageClicked. url: " + url)
    }

    let banner = <a href={props.desturl} target="_blank">
        <Image src={props.image} fluid onClick={() => bannerImageClicked(props.desturl)} /></a>
    let filter = isDashboard() ? <Filter></Filter> : null;
    return (
       isDashboard() ?
            <div className={classes.Banner}>
                <Row>
                    <Col>{banner}</Col>
                    <Col xs={10}>
                        {filter}
                    </Col>
                </Row>
            </div>
            :
            <div className={classes.Banner}>
                {banner}
            </div>
    )
}

const mapStateToProps = state => {
    return {
        filterCriteria: state.filterCriteria,
        loginState: state.loginState,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setFilterCriteria: filterCriteria =>
            dispatch({ type: SET_FILTER, payload: filterCriteria }),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Banner);
