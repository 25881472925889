import React from 'react';
import classes from './style.module.css'
import { Button } from 'react-bootstrap';
import { connect } from "react-redux"

const ProfileMenu = props => {
   const username = props.userSettingsState.email
   const buttonLabel = <span>Sign out (<span style={{color: 'yellow'}}>{username}</span>)</span>
   return (
       <div className={classes.ProfileMenu}>
            <Button size="sm" variant="dark" onClick={props.logoutClicked}>{buttonLabel}</Button>
       </div>
   );
}

const mapStateToProps = state => {
    return {
        userSettingsState: state.userSettingsState
    }
}


export default connect(mapStateToProps)(ProfileMenu);