import axios from 'axios';

const GPM_API_BASE_URL= "https://rpmapi.makebettinggreat.com/v1/";
const AGP_API_BASE_URL = "https://rdbapi.makebettinggreat.com/v1/"
const ENDPOINT_ODDS = AGP_API_BASE_URL + 'horse/odds/';
const API_WINNER = GPM_API_BASE_URL + 'horse/winners/';
const API_VOLUME_MATCHED = GPM_API_BASE_URL + 'horse/volumematched/';
const ENDPOINT_REPORT_ALL = AGP_API_BASE_URL +'race/detail/report/';
const ENDPOINT_ODDS_GPM = GPM_API_BASE_URL + 'horse/odds/';
const RACE_URL = "https://rdbapi.makebettinggreat.com/v1/race/"
const RACE_DETAIL_URL = "https://rdbapi.makebettinggreat.com/v1/race/"
const RACE_DETAIL_URL_ALL = "https://spmapi.makebettinggreat.com/v1/soccer/events/"
const MATCH_MARKET_ODD_CURRENT = "https://spmapi.makebettinggreat.com/v1/soccer/odds/"
const MATCH_CURRENT_SCORE = "https://rapidapiscrapper.makebettinggreat.com/v1/match/score/"
const MATCH_DFS_STAT = "https://dfsclientapi.makebettinggreat.com/v1/match/"
const MATCH_PREDICTIONS = "https://rapidapiscrapper.makebettinggreat.com/v1/match/prediction/"


export const getAllReport = async (date) => {
    const url = ENDPOINT_REPORT_ALL + date
    const result = await axios.get(url)
    const data = result.data
    if(result.status === 200) {
        return data;
    }else {
        return []
    }
}

export const getRaceDetails = async (race) => {
    const url = RACE_DETAIL_URL + race.raceId + "/runner"
    const result = await axios.get(url)
    const data = result.data
    if(result.status === 200) {
        return data;
    }else {
        return []
    }
}

export const getRaceSummary = async (race) => {
    const url = RACE_DETAIL_URL + race.trackId + "/race/" + race.raceTime + "/summary"
    const result = await axios.get(url)
    const data = result.data
    if(result.status === 200) {
        return data;
    }else {
        return []
    }
}

export const getTodaysMatch = async (date) => {
    const url = RACE_DETAIL_URL_ALL + date;
    const result = await axios.get(url)
    const data = result.data
    if(result.status === 200) {
        return data;
    }else {
        return []
    }
}

export const getMarketOddCurrent = async (eventId, type) => {
    const url = MATCH_MARKET_ODD_CURRENT + type + "/" + eventId;
    const result = await axios.get(url)
    const data = result.data
    if(result.status === 200) {
        return data;
    }else {
        return []
    }
}

export const getMatchPredictions = async (eventId) => {
    const url = MATCH_PREDICTIONS +  eventId;
    const result = await axios.get(url)
    const data = result.data
    if(result.status === 200) {
        return data;
    }else {
        return  {};
    }
}

export const getCurrentScore = async (eventId) => {
    const url = MATCH_CURRENT_SCORE + eventId;
    const result = await axios.get(url)
    const data = result.data
    if(result.status === 200) {
        return data;
    }else {
        return []
    }
}

export const getDFSStat = async (eventId, type, date) => {
    const url = MATCH_DFS_STAT + eventId + "/" + date + "/stat/"+ type;
    const result = await axios.get(url)
    const data = result.data
    if(result.status === 200) {
        return data;
    }else {
        return []
    }
}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    return [year, month, day].join('');
}

export const getAllRaces = async (date) => {
    const url = RACE_URL + date;
    const result = await axios.get(url)
    const data = result.data
    if(result.status === 200) {
        return data;
    }else {
        return []
    }
}
export const getRunnerOddsByMarketId = async (marketId, selectionId, date) => {
    let endpoint = formatDate(new Date()) === date ?  ENDPOINT_ODDS_GPM : ENDPOINT_ODDS;
    const url = endpoint + marketId + "/" + selectionId;
    const result = await axios.get(url)
    const data = result.data
    if(result.status === 200) {
        return data;
    }else {
        return []
    }
}


export const getWinnerInfo = async (marketId, date, time) => {
    const endpoint = API_WINNER + date + "/"+ time +"/"  + marketId
    const result = await axios.get(endpoint)
    const data = result.data
    if(result.status === 200) {
        return data;
    }else {
        return []
    }
}

export const getVolumeMatched = async (marketId) => {
    const endpoint = API_VOLUME_MATCHED + marketId
    const result = await axios.get(endpoint)
    const data = result.data
    if(result.status === 200) {
        return data;
    }else {
        return []
    }
}

export const getMatchesByDate = async date => {
        return []
}