import React, { useState } from 'react';
import classes from './style.module.css';
import { Modal, Button } from 'react-bootstrap';

const ModalComponent = props => {
    const [show] = useState(props.show);

    return (
        <Modal show={show} className={classes.Modal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            {props.children}
            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={props.modalClosed}>Close</Button>
                <Button variant="success"  size="sm" onClick={props.modalSubmit}>Save Changes</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalComponent;