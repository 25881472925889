import React from 'react';
import classes from './style.module.css'
import Banner from "../../components/UI/Banner"
import { Row } from 'react-bootstrap';

const BannerRow = (props) => {
    return (
        <Row className={classes.BannerRow} noGutters>
            <Banner image={props.bannerImage} desturl={props.desturl}/>
        </Row>
    )
}

export default BannerRow;