import React, {useState} from 'react';
import classes from './style.module.css';
import PropTypes from 'prop-types';
import {logInfo/*, logError*/} from '../../../util/log/logger'
import { Form, Button, Row } from 'react-bootstrap';

import { CHANGE_PASSWORD_FORM_FIELDS } from '../../../constants/Constants'

const ChangePasswordForm = props => {

    const [password, setPassword] = useState("");
    const [passwordNew, setPasswordNew] = useState("");
    const [passwordNewVerify, setPasswordNewVerify] = useState("");
    const [inputErrorMessage, setInputErrorMessage] = useState(null);


    const compareInputs = () => {
        return passwordNew === passwordNewVerify && props.password === password
    }

    const changePasswordHandler = async () => {
        logInfo("changePasswordHandler called.")
        if(compareInputs()) {
            logInfo("changePasswordHandler | new and confirm passwords matched")
            setInputErrorMessage(null)
            await props.changePasswordClicked(passwordNew)
        }else {
            logInfo("changePasswordHandler | mismatch in new and confirm passwords")
            setInputErrorMessage("Incorrect passwords. Please check and re-enter again")
        }
    }

    let fieldErrors = null;
    if(inputErrorMessage) {
        fieldErrors = <Row className={classes.FieldErrors}>
                        <p>{inputErrorMessage}</p>
                      </Row>
    }

    return (
        <Form className={classes.ChangePasswordForm}>
            <p style={{color: 'black', fontWeight: 'bold', fontSize: '15px'}}>{props.title} - Change Your Password</p>
            <p style={{color: 'green'}}>Password minimum length is 8 and should adhere to the password policy.</p>
            <Form.Group controlId="loginFormPasswordInput">
                <Form.Label>{CHANGE_PASSWORD_FORM_FIELDS.PASSWORD_TEXT.label}</Form.Label>
                <Form.Control
                    key={CHANGE_PASSWORD_FORM_FIELDS.PASSWORD_TEXT.key}
                    type="password"
                    placeholder={CHANGE_PASSWORD_FORM_FIELDS.PASSWORD_TEXT.placeholder}
                    value={password}
                    onChange={(event) => { setPassword(event.target.value) }} />
            </Form.Group>

            <Form.Group controlId="loginFormPasswordInputNew">
                <Form.Label>{CHANGE_PASSWORD_FORM_FIELDS.PASSWORD_NEW_TEXT.label}</Form.Label>
                <Form.Control
                    key={CHANGE_PASSWORD_FORM_FIELDS.PASSWORD_NEW_TEXT.key}
                    type="password"
                    placeholder={CHANGE_PASSWORD_FORM_FIELDS.PASSWORD_NEW_TEXT.placeholder}
                    value={passwordNew}
                    onChange={(event) => { setPasswordNew(event.target.value); setInputErrorMessage(null) }} />
            </Form.Group>

            <Form.Group controlId="loginFormPasswordInputNewVerify">
                <Form.Label>{CHANGE_PASSWORD_FORM_FIELDS.PASSWORD_NEW_VERIFY_TEXT.label}</Form.Label>
                <Form.Control
                    key={CHANGE_PASSWORD_FORM_FIELDS.PASSWORD_NEW_VERIFY_TEXT.key}
                    type="password"
                    placeholder={CHANGE_PASSWORD_FORM_FIELDS.PASSWORD_NEW_VERIFY_TEXT.placeholder}
                    value={passwordNewVerify}
                    onChange={(event) => { setPasswordNewVerify(event.target.value); setInputErrorMessage(null) }} />
            </Form.Group>
            {fieldErrors}
            <Button variant="primary"
                onClick={changePasswordHandler}>
                {CHANGE_PASSWORD_FORM_FIELDS.CHANGE_PASSWORD_BUTTON.label}
            </Button>
        </Form>
    )
}

ChangePasswordForm.propTypes = {
    password: PropTypes.string.isRequired,
    changePasswordClicked: PropTypes.func.isRequired
}

export default ChangePasswordForm