import React from 'react';
import classes from './style.module.css'
import ApplicationLogin from '../../components/ApplicationLogin'
import { Container, Row } from 'react-bootstrap';
import BannerRow from "../BannerRow"
import HeaderRow from "../HeaderRow"
import advertisementImage from "../../assets/images/advertisement.jpg"
import headerImage from '../../assets/images/header.png'


const LoginView = (props) => {

    const advertisementStyle = {
        margin: 0,
        bottom: '50px',
        position: 'absolute',
        textAlign: 'center',
        width: '100%'
    }

    return (
        <Container className={classes.LoginView} fluid>
            <BannerRow bannerImage={headerImage} />
            <HeaderRow active={false} />
            <Row className={classes.MiddleRow}>
                <ApplicationLogin />
            </Row>
            <div style={advertisementStyle}>
                <BannerRow bannerImage={advertisementImage} desturl="http://football-atm.com/ms.htm" />
            </div>
        </Container>
    );
}

export default LoginView;