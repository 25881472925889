
import { Auth } from "aws-amplify";
import { APP_ROLES } from '../../constants/Constants'


const checkUserHasRequiredRole = userGroups => {
  let result = false
  //console.log(userGroups)
  //console.log(APP_ROLES)
  userGroups.forEach(group => {
    APP_ROLES.forEach(role => {
      if (role === group) result = true
    })
  });
  return result
}

const getAuthenticatedUser = async () => {
  const user = await Auth.currentAuthenticatedUser();
  console.log(user)
  
  const currentSession = await Auth.currentSession()
  console.log(currentSession)

  const userGroups = currentSession.accessToken.payload["cognito:groups"];
  if (userGroups === undefined || !checkUserHasRequiredRole(userGroups)) {
    throw new Error("You don't have access to this application. Please contact site administrator.");
  }
  return user;
}

const userSignIn = async (username, password) => {
  const user = await Auth.signIn(username, password);
  console.log(user);
  return user;
}

const getUserSettings = async () => {
  const info = await Auth.currentUserInfo();
  return info;
}

const logout = async () => {
  await Auth.signOut();
}

const forgotPassword = async username => {
  await Auth.forgotPassword(username);
}

const forgotPasswordSubmit = async (username, password, verificationCode) => {
  await Auth.forgotPasswordSubmit(
    username,
    verificationCode,
    password
  );
}

const requestChangePassword = async (currentUser, password) => {
   const user = await Auth.completeNewPassword(
    currentUser,               // the Cognito User Object
    password,       // the new password
  );
  return user
}

export { userSignIn, getUserSettings, getAuthenticatedUser, logout, forgotPassword, forgotPasswordSubmit, requestChangePassword }