
import { getPreviousNthDayStr } from '../../util/date/date-utils';
import { SET_USER_SETTINGS_STATE } from '../actions'
import { SET_LOGIN_DETAILS_STATE } from '../actions'
import { SET_LOGIN_STATE } from '../actions'
import { SET_MODAL_STATE } from '../actions'
import { SET_TIME_ZONE_SETTINGS_STATE } from '../actions'
import { SET_SELECTION_STATE } from '../actions'
import { SET_FILTER } from '../actions'
import { SET_SOCCER_MATCHES_STATE } from '../actions'
import { SET_SOCCER_LEAGUES_STATE } from '../actions'

import { LOGIN_STATUS, APP_DISPLAY_NAME, APP_VERSION, HOME, AWAY, TICKLER_TAB_BUTTON } from '../../constants/Constants'

const initialState = {
    appInfoState: {
        appTitle: APP_DISPLAY_NAME + " - " + APP_VERSION
    },
    loginState: {
        isLoggedIn: false,
        loginStatus: LOGIN_STATUS.LOGGED_IN,
        errorMessage: null,
        successMessage: null,
        userDetails: {
            username: null,
            password: null,
            email: null
        }
    },
    loginDetailsState: {
        savePasswordFlag: false,
        username: null,
        password: null
    },
    applicationSettingsState: {
    },
    timeZoneSettingsState: {
        localTimeOffSetToGmt: 60,
        localTimeZone: "GMT+01:00"
    },
    userSettingsState: {
    },
    betfairLoginState: null,
    selectionState: {
        tab: TICKLER_TAB_BUTTON.HOT_JOCKEY, // league or match
        selectionDate: getPreviousNthDayStr(0),
        home: HOME,
        away: AWAY
    },
    filterCriteria: {},
    predictions: {},
    soccerLeaguesState: [],
    soccerMatchesState: [],
    modalState: {
        show: false,
        component: null,
        onHide: () => { },
        onClose: () => { },
        onSubmit: (closed) => { closed() }
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_SETTINGS_STATE:
            return {
                ...state,
                userSettingsState: action.payload,
            }

        case SET_LOGIN_DETAILS_STATE:
            return {
                ...state,
                loginDetailsState: action.payload,
            }

        case SET_LOGIN_STATE:
            return {
                ...state,
                loginState: action.payload,
            }

        case SET_MODAL_STATE:
            return {
                ...state,
                modalState: action.payload,
            }

        case SET_TIME_ZONE_SETTINGS_STATE:
            return {
                ...state,
                timeZoneSettingsState: action.payload,
            }

        case SET_SELECTION_STATE:
            return {
                ...state,
                selectionState: action.payload,
            }

        case SET_SOCCER_MATCHES_STATE:
            return {
                ...state,
                soccerMatchesState: action.payload,
            }

        case SET_SOCCER_LEAGUES_STATE:
            return {
                ...state,
                soccerLeaguesState: action.payload,
            }
        case SET_FILTER:
            return {
                ...state,
                filterCriteria: action.payload,
            }
        default:
    }
    return state
}

export default reducer