import React, { useState } from 'react';
import classes from './style.module.css'
import { Col } from 'react-bootstrap';
import ProfileMenu from "../UI/ProfileMenu"
import ExportData from "../ExportData"
import { SET_SELECTION_STATE, SET_SOCCER_LEAGUES_STATE, SET_SOCCER_MATCHES_STATE } from "../../redux/actions"
import { connect } from "react-redux"
import { passDateToString } from '../../util/date/date-utils'
import { currentDateStr } from '../../util/date/date-utils'
import DatePicker from '../../components/UI/DatePicker'
import ToggleButtonDouble from "../UI/ToggleButtonDouble"
import { HOME, AWAY, HEADER_CONTROLS, TOTAL } from "../../constants/Constants"

import bookIcon from "../../assets/images/book.png"
//import RaceSlidingPane from '../RaceSlidingPane';
import "react-sliding-pane/dist/react-sliding-pane.css";
import Clock from 'react-live-clock';

const Header = props => {
    const [selectionDate, setSelectionDate] = useState(currentDateStr())
    const [isShowLogin, setIsShowLogin] = useState(false);

    const handleLoginClick = () => {
        console.log(".....clicked")
        setIsShowLogin((isShowLogin) => !isShowLogin);
      };

    const selectionDateChangeHandler = (event) => {
        const newDate = passDateToString(event);
        const selectionState = { ...props.selectionState };
        selectionState.selectionDate = newDate;
        props.setSelectionState(selectionState);
        setSelectionDate(newDate)
    }

    const homeStatToggleHandler = () => {
        console.log(`homeStatToggleHandler. current away stat mode: ${props.selectionState.home}`)
        const newValue = props.selectionState.home === HOME ? TOTAL : HOME
        console.log(`homeStatToggleHandler. new away stat mode: ${newValue}`)
        const selectionState = {...props.selectionState}
        selectionState.home = newValue
        props.setSelectionState(selectionState)
    }

    const awayStatToggleHandler = () => {
        console.log(`awayStatToggleHandler. current away stat mode: ${props.selectionState.away}`)
        const newValue = props.selectionState.away === AWAY ? TOTAL : AWAY
        console.log(`awayStatToggleHandler. new away stat mode: ${newValue}`)
        const selectionState = {...props.selectionState}
        selectionState.away = newValue
        props.setSelectionState(selectionState)
    }

    return (
        <>
            <Col className={classes.HeaderLeft} xs={6}>
                <ExportData label={HEADER_CONTROLS.EXPORT_DATA_BUTTON.label} mode={props.selectionState.mode}
                    selectionDate={props.selectionState.selectionDate}
                    currentTab={props.selectionState.tab}
                    home={props.selectionState.home}
                    away={props.selectionState.away} />
                <a href="https://winningmore.com/manual.htm" target="_blank">
                <figure>
                <img style={{ width: "33px", height: "26px", marginTop: "3px", marginLeft: "50px" }} src={bookIcon} />
              
                            <figcaption><font style={{ marginLeft:'22px', color:'#FFFFFF', fontSize: '0.7em' }} >
                                SPM User Guide
                                </font>
                                </figcaption>
                        </figure>
                </a>

                <font style={{ marginLeft:'50px', marginTop:'13px', color:'#FFFFFF', fontSize: '.75em' }} >
                     GMT Time : <Clock format={'(HH:mm:ss), DD/MM/YYYY'} ticking={true} timezone={'GMT'} />
                </font>
            </Col>
            <Col className={classes.HeaderRight} xs={6}>
                <DatePicker selectedDate={selectionDate} selectedDateChanged={selectionDateChangeHandler} />
                <ToggleButtonDouble clicked={homeStatToggleHandler} active={props.selectionState.home === HOME} label0={HEADER_CONTROLS.TOGGLE_HOME_BUTTON.label0} label1={HEADER_CONTROLS.TOGGLE_HOME_BUTTON.label1}/>
                 <ToggleButtonDouble clicked={awayStatToggleHandler} active={props.selectionState.away === AWAY} label0={HEADER_CONTROLS.TOGGLE_AWAY_BUTTON.label0} label1={HEADER_CONTROLS.TOGGLE_AWAY_BUTTON.label1} />
                 <ProfileMenu logoutClicked={props.logoutClicked} />
            </Col>
        </>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        selectionState: state.selectionState,
        ...ownProps
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setSoccerLeaguesState: payload =>
            dispatch({ type: SET_SOCCER_LEAGUES_STATE, payload: payload }),
        setSelectionState: payload =>
            dispatch({ type: SET_SELECTION_STATE, payload: payload }),
        setSoccerMatchesState: payload =>
            dispatch({ type: SET_SOCCER_MATCHES_STATE, payload: payload }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);