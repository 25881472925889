import React, { useEffect, useState } from 'react';
import { Container, Table, Row, Col, Card } from 'react-bootstrap';
import classes from './style.module.css'
import League from './League'
import { connect } from "react-redux"
import { HORSE_TABLE_COLUMNS_STATIC, HOME, AWAY} from '../../constants/Constants'
import { SET_SOCCER_LEAGUES_STATE, SET_SELECTION_STATE, SET_FILTER } from "../../redux/actions"
import { getTodaysMatch } from '../../service/backend-api-service';
import Spinner from "../UI/Spinner"
import "react-sliding-pane/dist/react-sliding-pane.css"
import sortIconImage from "../../assets/images/sort_both.png"

const LeagueTable = (props) => {
    const [sortOrder, setSortOrder] = useState(true) // true - ascending and false - descending
    const [sortColumn, setSortColumn] = useState(null)
    const [loadingState, setLoadingState] = useState(1)
    const [race, setRace] = useState({})
    const [runner, setRunner] = useState({})
    const [oddFilter, setOddFilter] = useState({})
    const [durationFilter, setDurationFilter] = useState({})
    const [filterCriteria, setFilterCriteria] = useState({})


    const staticColumns = []
    for (let value in HORSE_TABLE_COLUMNS_STATIC) staticColumns.push(HORSE_TABLE_COLUMNS_STATIC[value])

    const finalColumns = [...staticColumns];

    const sortIconClickedHandler = columnId => {
        setSortColumn(columnId)
        if (sortColumn === columnId) setSortOrder(!sortOrder)
    }

    const tableHeaders = finalColumns.map(column => {
        let desc = column.description;
        let sortIcon = null
        if (column === HORSE_TABLE_COLUMNS_STATIC.HOME_WIN_PERCENT ||
            column === HORSE_TABLE_COLUMNS_STATIC.DRAW_WIN_PERCENT ||
            column === HORSE_TABLE_COLUMNS_STATIC.AWAY_WIN_PERCENT ||
            column === HORSE_TABLE_COLUMNS_STATIC.COMB_GOALS_SCORED_CONCEDED ||
            column === HORSE_TABLE_COLUMNS_STATIC.COMB_OVER_TWO_POINT_FIVE ||
            column === HORSE_TABLE_COLUMNS_STATIC.COMB_BTTS) {
            sortIcon = <img src={sortIconImage} alt="Sort" onClick={() => sortIconClickedHandler(column.id)} />
        }
        if (column === HORSE_TABLE_COLUMNS_STATIC.SCORE) {
            desc = (
                <>
                    <div style={{ float: "left", marginLeft: "10px" }}>CS</div>
                    <div style={{ float: "left", width: "75px", marginLeft: "5px" }}>Status</div>
                    <div style={{ float: "left", marginLeft: "5px" }}>Time</div>
                    <div style={{ marginLeft: "155px" }}>HT</div>
                </>
            )
        }
        if (column === HORSE_TABLE_COLUMNS_STATIC.DFS_STAT) {
            desc = (
                <>
                    <div style={{ float: "left", width: "10px" }}>CGS+GCPM</div>
                    <div style={{ float: "left", marginLeft: "5px" }}>CO2.5</div>
                    <div style={{ float: "left", marginLeft: "5px" }}>CBTTS</div>
                </>
            )
        }
        if (column === HORSE_TABLE_COLUMNS_STATIC.ODD_DETAILS) {
            desc = (
                <>
                    <div style={{ float: "left", marginLeft: "30px" }}>Home</div>
                    <div style={{ float: "left", marginLeft: "10px" }}>Draw</div>
                    <div style={{ float: "left", marginLeft: "10px" }} >Away</div>
                    <div style={{ float: "left", marginLeft: "33px" }}>Volume</div>
                    <div style={{ float: "left", marginLeft: "25px" }}>U 2.5</div>
                    <div style={{ float: "left", marginLeft: "16px" }}>O 2.5</div>
                    <div style={{ float: "left", marginLeft: "17px" }}>Volume</div>
                    <div style={{ float: "left", marginLeft: "9px" }}>Score</div>
                    <div style={{ float: "left", marginLeft: "10px" }}>Volume</div>
                </>
            )
        }
        return (
            <th key={column.id}>{desc}{sortIcon}</th>
        );
    })

    const updateCalculatedColumns = matches => {
        //logInfo(`MatchTable | updateCalculatedColumns | update calculated columns. count: ${matches.length}`)
        matches = matches.map(match => {
            match.combGoalsScoredConc = "-"
            match.combOverTwoPointFive = "-"
            match.combBtts = "-"
            if (match.matchStat && match.matchStat.homeStat !== null && match.matchStat.awayStat !== null) {
                const cellValueCombGoalsScoredConc = getAverageValue(props.selectionState.home === HOME ? match.matchStat.homeStat.avgGoalsScoredConc : match.matchStat.homeStat.avgGoalsScoredConcTotal,
                    props.selectionState.away === AWAY ? match.matchStat.awayStat.avgGoalsScoredConc : match.matchStat.awayStat.avgGoalsScoredConcTotal)
                match.combGoalsScoredConc = !isNaN(cellValueCombGoalsScoredConc) ? parseFloat(cellValueCombGoalsScoredConc) : "-"

                const cellValueCombOverTwoPointFive = getAverageValue(props.selectionState.home === HOME ? match.matchStat.homeStat.overTwoPointFiveGoals : match.matchStat.homeStat.overTwoPointFiveGoalsTotal,
                    props.selectionState.away === AWAY ? match.matchStat.awayStat.overTwoPointFiveGoals : match.matchStat.awayStat.overTwoPointFiveGoalsTotal)
                match.combOverTwoPointFive = !isNaN(cellValueCombOverTwoPointFive) ? parseFloat(cellValueCombOverTwoPointFive) : "-"

                const cellValueCombBtts = getAverageValue(props.selectionState.home === HOME ? match.matchStat.homeStat.bothTeamsScored : match.matchStat.homeStat.bothTeamsScoredTotal,
                    props.selectionState.away === AWAY ? match.matchStat.awayStat.bothTeamsScored : match.matchStat.awayStat.bothTeamsScoredTotal)
                match.combBtts = !isNaN(cellValueCombBtts) ? parseFloat(cellValueCombBtts) : "-"
            }
            return match
        })
        return matches
    }

    const getAverageValue = (a, b) => {
        let result = "-"
        if (a !== null && b !== null && a !== "-" && b !== "-") {
            result = (a + b) / 2
            result = result.toFixed(2)
        }
        return result
    }

    useEffect(() => {
        const fetchData = async () => {
            let date = props.selectionState.selectionDate
            if (date) {
                setLoadingState(0)
                let apiData = await getTodaysMatch(date)
                apiData = updateCalculatedColumns(apiData)
                props.setSoccerLeaguesState(apiData)
                if (apiData) {
                    setLoadingState(2)
                } else {
                    setLoadingState(1)
                }
            }
        }
        setRunner({});
        setFilterCriteria(null);
        fetchData();
        
    }, [props.selectionState.selectionDate])


    const sortPrediction = (a, b) => {
        if (sortColumn === HORSE_TABLE_COLUMNS_STATIC.HOME_WIN_PERCENT.id) {
            return compare(a?.prediction?.homePercent, b?.prediction?.homePercent);
        } else if (sortColumn === HORSE_TABLE_COLUMNS_STATIC.AWAY_WIN_PERCENT.id) {
            return compare(a?.prediction?.awayPercent, b?.prediction?.awayPercent);
        } else if (sortColumn === HORSE_TABLE_COLUMNS_STATIC.DRAW_WIN_PERCENT.id) {
            return compare(a?.prediction?.drawPercent, b?.prediction?.drawPercent);
        } else if (sortColumn === HORSE_TABLE_COLUMNS_STATIC.COMB_GOALS_SCORED_CONCEDED.id) {
            return compare(a?.combGoalsScoredConc, b?.combGoalsScoredConc);
        } else if (sortColumn === HORSE_TABLE_COLUMNS_STATIC.COMB_OVER_TWO_POINT_FIVE.id) {
            return compare(a?.combOverTwoPointFive, b?.combOverTwoPointFive);
        } else if (sortColumn === HORSE_TABLE_COLUMNS_STATIC.COMB_BTTS.id) {
            return compare(a?.combBtts, b?.combBtts)
        }
        return 0;
    }

    const compare = (a, b) => {
        if (a === b) return 0
        if (a === null || a === "-") return 1
        if (b === null || b === "-") return -1
        if (sortOrder) return a < b ? -1 : 1;
        else return a < b ? 1 : -1;
    }

    useEffect(() => {
        var sortedSoccerLeagueState = props.soccerLeaguesState.sort(sortPrediction);
        sortedSoccerLeagueState = updateCalculatedColumns(sortedSoccerLeagueState)
        props.setSoccerLeaguesState(sortedSoccerLeagueState);
    }, [sortColumn, sortOrder, props.selectionState.home, props.selectionState.away])

    useEffect(() => {
        setRace({});
        setRunner({});
        setLoadingState(1)
        setFilterCriteria(null);
        props.setSoccerLeaguesState(null)
    }, [props.selectionState.selectionDate])


    useEffect(() => {
        console.log("new filter..." + JSON.stringify(props.filterCriteria))
        setFilterCriteria(props.filterCriteria);
    }, [props.filterCriteria])

    const objectValue = (myObject, property) => {
          return myObject && myObject.hasOwnProperty(property) ? myObject[property] : null;
    }

    const applyFilter = (element) => {
        var filterCriteria = props.filterCriteria;
        
        if(!filterCriteria || Object.keys(filterCriteria).length === 0){
            return true;
        }
        if (!filterCriteria.market && !filterCriteria.matchDurationMin > 0  &&
            !filterCriteria.matchDurationMax > 0 &&
            !filterCriteria.oddVolume > 0 &&
            !filterCriteria.underOverVolumne > 0 &&
            !filterCriteria.correctScoreVolumne > 0) {
            return true;
        }
        /*
        props.setFilterCriteria({
             "market" : market,
             "selection" : selection,
             "minOdd" : minOdd,
             "maxOdd" : maxOdd
         });
          <Dropdown.Item eventKey="Match Odds">Match Odds</Dropdown.Item>
                                     <Dropdown.Item eventKey="Under Over">Under Over</Dropdown.Item>
                                     <Dropdown.Item eventKey="Correct Score">Correct Score</Dropdown.Item>
         */
        var currentStatus = durationFilter[element.bfEventId];
        var matchToPick = (currentStatus && currentStatus.status && currentStatus.status.shortName === 'FT') ? 'start' : 'current';
        if (filterCriteria.matchDurationMin && filterCriteria.matchDurationMin > 0 && 
            filterCriteria.matchDurationMax && filterCriteria.matchDurationMax > 0) {
            var filterDurationMin = filterCriteria.matchDurationMin
            var filterDurationMax = filterCriteria.matchDurationMax
           
            if (currentStatus && currentStatus.status &&
                (currentStatus.status.elapsed < filterDurationMin || currentStatus.status.elapsed > filterDurationMax || currentStatus.status.elapsed == 90)) {
                return false;
            }
            if(!currentStatus || !currentStatus.status){
                return false;
            }
        }
        if (filterCriteria.oddVolume && filterCriteria.oddVolume > 0) {
            var oddVolume = filterCriteria.oddVolume
            var key = element.bfEventId + matchToPick;
            var odd = objectValue(oddFilter, key);
            let value = objectValue(odd, 'matchOddsVolume');
            if (value !== null && value < oddVolume) {
                return false;
            }
        }

        if (filterCriteria.underOverVolumne && filterCriteria.underOverVolumne > 0) {
            var underOverVolumne = filterCriteria.underOverVolumne
            var key = element.bfEventId + matchToPick;
            var odd = objectValue(oddFilter, key);
            let value = objectValue(odd, 'overUnderTwoPFiveVolume');
            if (value !== null && value < underOverVolumne) {
                return false;
            }
        }
        if (filterCriteria.correctScoreVolumne && filterCriteria.correctScoreVolumne > 0) {
            var correctScoreVolumne = filterCriteria.correctScoreVolumne
            var key = element.bfEventId + matchToPick;
            var odd = objectValue(oddFilter, key);
            let value = objectValue(odd, 'ftCorrectScoreVolume');
            if (value !== null && value < correctScoreVolumne) {
                return false;
            }
        }

        if (filterCriteria.selectionOdd) {
            var key = element.bfEventId + matchToPick;
            var odd = objectValue(oddFilter, key);
            if (odd) {
                    let value = objectValue(odd, 'matchOdds' + filterCriteria.selectionOdd + filterCriteria.priceOdd);
                    if (value === null) {
                        return false
                    }
                    if (value !== null && (value < filterCriteria.minOddOdd || value > filterCriteria.maxOddOdd)){
                        return false;
                    }
                }
            }else {
                return false;
            }
            if (filterCriteria.selectionUnderOver) {
                var key = element.bfEventId + matchToPick;
                let odd = objectValue(oddFilter, key);
                if(odd){
                    let value = objectValue(odd, filterCriteria.selectionUnderOver.toLowerCase() + 'TwoPFive' + filterCriteria.priceUnderOver);
                    if (value === null ) {
                        return false
                    }
                    if (value !== null && (value < filterCriteria.minOddUnderOver || value > filterCriteria.maxOddUnderOver))
                        return false;
                }else{
                    return false;
                }
            }
            if (filterCriteria.selectionCorrectScore) {
                var key = element.bfEventId + matchToPick;
                let odd = objectValue(oddFilter, key);
                if(odd){
                    let value = objectValue(odd, 'ftCorrectScore' + convertCorrectScore(filterCriteria.selectionCorrectScore) + filterCriteria.priceCorrectScore);
                    if (value === null) {
                        return false
                    }
                    if (value !== null && (value < filterCriteria.minOddCorrectScore || value > filterCriteria.maxOddCorrectScore))
                        return false;
                }else{
                    return false;
                }
            }
            if(filterCriteria.minBtts != 0 || filterCriteria.maxBtts != 100){
                if(element.combBtts){
                    const value = element.combBtts;
                    if (value !== null && (value < filterCriteria.minBtts || value > filterCriteria.maxBtts))
                        return false;
                }else{
                    return false;
                }
            }
            if(filterCriteria.minCS25 != 0 || filterCriteria.maxCS25 != 100){
                if(element.combOverTwoPointFive){
                    const value = element.combOverTwoPointFive;
                    if (value !== null && (value < filterCriteria.minCS25 || value > filterCriteria.maxCS25))
                        return false;
                }else{
                    return false;
                }
            }
            if(filterCriteria.minCombGS != 0 || filterCriteria.maxCombGS != 100){
                if(element.combGoalsScoredConc){
                    const value = element.combGoalsScoredConc;
                    if (value !== null && (value < filterCriteria.minCombGS || value > filterCriteria.maxCombGS))
                        return false;
                }else{
                    return false;
                }
            }
           
            if(filterCriteria.homeMin != 0 || filterCriteria.homeMax != 100){
                if(element.rapidApiStat && element.rapidApiStat.home){
                    const value = element.rapidApiStat.home.total;
                    if (value === -1 || (value < filterCriteria.homeMin || value > filterCriteria.homeMax))
                        return false;
                }else{
                    return false;
                }
            }
            if(filterCriteria.awayMin != 0 || filterCriteria.awayMax != 100){
                if(element.rapidApiStat && element.rapidApiStat.away){
                    const value = element.rapidApiStat.away.total;
                    if (value === -1 || (value < filterCriteria.awayMin || value > filterCriteria.awayMax))
                        return false;
                }else{
                    return false;
                }
            }
        return true;
    }

    const convertCorrectScore = (score) =>{
        if(score === '0-0'){
            return "ZeroZero";
        }else if(score === '0-1'){
            return "ZeroOne";
        }else if(score === '0-2'){
            return "ZeroTwo";
        }else if(score === '0-3'){
            return "ZeroThree";
        }else if(score === '1-0'){
            return "OneZero";
        }else if(score === '1-1'){
            return "OneOne";
        }else if(score === '1-2'){
            return "OneTwo";
        }else if(score === '1-3'){
            return "OneThree";
        }else if(score === '2-0'){
            return "TwoZero";
        }else if(score === '2-1'){
            return "TwoOne";
        }else if(score === '2-2'){
            return "TwoTwo";
        }else if(score === '2-3'){
            return "TwoThree";
        }else if(score === '3-0'){
            return "ThreeZero";
        }else if(score === '3-1'){
            return "ThreeOne";
        }else if(score === '3-2'){
            return "ThreeTwo";
        }else if(score === '3-3'){
            return "ThreeThree";
        }else if(score === 'Other Home Win'){
            return "AoHw";
        }else if(score === 'Other Away Win'){
            return "AoAw";
        }else if(score === 'Other Draw Win'){
            return "AoDw";
        }
        return score;

    }

    const clickHandler = (runner) => {
        setRunner(runner);
    }

    const oddChangeHandler = (eventId, type, odd) => {
        oddFilter[eventId + type] = odd
        setOddFilter(oddFilter)
    }

    const matchStatusChangeHandler = (eventId, score) => {
        durationFilter[eventId] = score
        setDurationFilter(durationFilter)
    }

    const testFilter = (item) => {
        return item.bfEventId === "31348657" || item.bfEventId === "31924106";
    }
    let itemList = props.soccerLeaguesState ?
        props.soccerLeaguesState.filter(applyFilter).map((item, index) => {
            return <League key={item.bfHomeTeam + index} date={props.selectionState.selectionDate}
                time={race.raceTime}
                selectionState={props.selectionState}
                finalColumns={finalColumns}
                clickHandler={clickHandler}
                oddChangeHandler={oddChangeHandler}
                matchStatusChangeHandler={matchStatusChangeHandler}
                item={item}
                race={race}
                index={index} />
        }) : null;


    let contentBody = null

    if (loadingState === 0) {
        contentBody = <Spinner />
    } else if (loadingState === 1) {
        contentBody = <div style={{ textAlign: "center" }}><Spinner /></div>
    } else {
        contentBody =
            <>
                <Table className="LeagueTable" style={{ fontSize: "10px" }} id="runnerDetails" triped bordered hover size="sm">
                    <thead>
                        <tr>
                            {tableHeaders}
                        </tr>
                    </thead>
                    <tbody>
                        {itemList}
                    </tbody>
                </Table>
            </>
    }
    return (
        <Container fluid className={classes.LeagueTable}>
            <Row style={{ marginLeft: "0px" }}>
                <Col style={{ paddingLeft: "1px" }} > {contentBody}</Col>
            </Row>
        </Container>
    )
}


const mapStateToProps = state => {
    return {
        soccerLeaguesState: state.soccerLeaguesState,
        selectionState: state.selectionState,
        filterCriteria: state.filterCriteria,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setSoccerLeaguesState: payload =>
            dispatch({ type: SET_SOCCER_LEAGUES_STATE, payload: payload }),
        setSelectionState: payload =>
            dispatch({ type: SET_SELECTION_STATE, payload: payload }),
        setFilterCriteria: payload =>
            dispatch({ type: SET_FILTER, payload: payload }),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LeagueTable);