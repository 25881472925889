import React from 'react';
import classes from './style.module.css'
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { passStringToDateWithFormat } from '../../../util/date/date-utils'

const DatePicker = props => {

   return (
       <div className={classes.DatePicker}>
                <span style={{fontSize:'.75em', color:'white'}}>Date:</span>
                <span>
                <ReactDatePicker className={classes.DatePicker}
                    dateFormat="yyyy/MM/dd"
                    selected={passStringToDateWithFormat(props.selectedDate, 'YYYYMMDD')}
                    onChange={(event) => props.selectedDateChanged(event)} 
                    />
                </span>
       </div>
   );
}

export default DatePicker;