import React from 'react';
import classes from './style.module.css'
import { Button } from 'react-bootstrap';


const ToggleButton = props => {
   return (
       <div className={classes.ToggleButton}>
            <Button size="sm" variant={props.active ? "success" : "danger" } disabled={!props.active} onClick={props.clicked}>{props.label0}</Button>
            <Button size="sm" variant={!props.active ? "success" : "danger" } disabled={props.active} onClick={props.clicked}>{props.label1}</Button>
       </div>
   );
}

export default ToggleButton;