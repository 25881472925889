import React, { useState, useEffect } from 'react';
import { getCurrentScore } from '../../../../service/backend-api-service';
import { Button } from 'react-bootstrap';

const LiveScore = (props) => {
    const [score, setScore] = useState("-")

    useEffect(() => {
        const fetchData = async () => {
            if (score == "-" || score.status.longName != 'Match Finished') {
                let eventId = props.eventId;
                let scoreAjax = await getCurrentScore(eventId)
                props.matchStatusChangeHandler(eventId, scoreAjax)
                setScore(scoreAjax)
            }
        }
        fetchData();
        const interval = setInterval(() => fetchData() , 5 * 1000 * 60  );
       
        return () => clearInterval(interval);
    }, [props.eventId, props.date])


    const formatGoal = (home, away) =>{
        if((home === null && away === null) || (home === -1 && away === -1)){
            return '- -';
        }
        return home + "-" + away;
    }

    return (
        <div style={{ float: "left",  margin: "5px", marginTop: "0px" }}>
            <div style={{ float: "left", marginLeft: "5px" }}>
                {score.goal?
                    formatGoal(score.goal.home,score.goal.away) : "-"}
            </div>
            <div style={{ float: "left", marginLeft: "5px" }}>
                {score.status && score.status.longName ?
                    ((score.status.elapsed > 84 &&  score.status.longName === 'SecondHalfKickOff') ? 'Match Finished' : score.status.longName) : ""}
            </div>
            <div style={{ float: "left",  marginLeft: "9px" }}>
                {score.status && score.status.longName !== 'Match Finished' && score.status.elapsed ?
                    score.status.elapsed : ""}
            </div>
            <div style={{ float: "left",  marginLeft: "35px" }}>
                {score.score && score.score.halftime ?
                    formatGoal(score.score.halftime.home,score.score.halftime.away) : "-"}
            </div>
        </div>
    )
}

export default LiveScore;