import React, { useEffect, useState } from 'react';
import classes from './style.module.css';
import { connect } from "react-redux"
import { Redirect } from 'react-router-dom'
import { Alert } from 'react-bootstrap';
import {logInfo, logError} from '../../util/log/logger'

// constants imports
import { LOGIN_PAGE_FORM_FIELDS, COGNITO_USER_SETTINGS_KEY, LOGIN_STATUS, APP_DISPLAY_NAME, COGNITO_EMAIL_KEY} from '../../constants/Constants'

//dependant components imports
import LoginForm from './LoginForm'
import ChangePasswordForm from './ChangePasswordForm'
import ForgotPassword from './ForgotPasswordForm'

//actions imports
import { SET_LOGIN_DETAILS_STATE } from "../../redux/actions"
import { SET_LOGIN_STATE } from "../../redux/actions"
import { SET_USER_SETTINGS_STATE } from "../../redux/actions"

import { getUserSettings, userSignIn, getAuthenticatedUser, requestChangePassword, forgotPassword, forgotPasswordSubmit } from "../../util/login/application-login-utils"

const ApplicationLogin = props => {

    const [loginDetails, setLoginDetails] = useState(props.loginDetailsState);
    const [user, setUser] = useState(null);

    useEffect(() => {
        console.log("useEffect")
    }, [])

    const loginDetailsChangedHandler = (key, value) => {
        const loginDetailsLocal = { ...loginDetails }
        console.log({ key: key, value: value });
        switch (key) {
            case LOGIN_PAGE_FORM_FIELDS.USERNAME_TEXT.key:
                loginDetailsLocal.username = value;
                break;
            case LOGIN_PAGE_FORM_FIELDS.PASSWORD_TEXT.key:
                loginDetailsLocal.password = value;
                break;
            case LOGIN_PAGE_FORM_FIELDS.SAVE_PASSWORD_FLAG_CHECK_BOX.key:
                loginDetailsLocal.savePasswordFlag = value;
                break;
            default:
        }
        setLoginDetails(loginDetailsLocal)
    }

    const loadUserSettings = async () => {
        try {
            const info = await getUserSettings()
           // console.log('loadUserSettings: info: ', info)
            const userSettingsStr = info ? info.attributes[COGNITO_USER_SETTINGS_KEY] : null
            if (userSettingsStr) {
                const userSettingsObj = JSON.parse(userSettingsStr);
                props.setUserSettingsState(userSettingsObj)
            }

            const userEmail = info ? info.attributes[COGNITO_EMAIL_KEY] : null
            if(userEmail !== null) {
                const userSettingsState = { ...props.userSettingsState}
                userSettingsState.email = userEmail
                props.setUserSettingsState(userSettingsState)  
            }
        } catch (error) {
            console.log("Error", error);
        }
    }

    const updateLoginStateWithUserDetails = (isLoggedIn, loginStatus, successMsg, errorMsg, username, password, email) => {
        const loginState = { ...props.loginState }
        loginState.isLoggedIn = isLoggedIn;
        loginState.loginStatus =loginStatus;
        loginState.errorMessage = errorMsg;
        loginState.successMessage = successMsg;
        loginState.userDetails.username = username;
        loginState.userDetails.password = password;
        loginState.userDetails.email = email;
        if(loginError) logError('updateLoginState: loginState: ', loginState)
        else logInfo('updateLoginState: loginState: ', loginState)
        props.setLoginState(loginState);
    }

    const updateLoginState = (isLoggedIn, loginStatus, successMsg, errorMsg) => {
        const loginState = { ...props.loginState }
        loginState.isLoggedIn = isLoggedIn;
        loginState.loginStatus =loginStatus;
        loginState.errorMessage = errorMsg;
        loginState.successMessage = successMsg;
        logInfo('updateLoginState: loginState: ', loginState)
        props.setLoginState(loginState);
    }

    const clearLoginStateSuccessAndErrorMessages = () => {
        const loginState = { ...props.loginState }
        loginState.errorMessage = null;
        loginState.successMessage = null;
        logInfo('clearLoginStateSuccessAndErrorMessages: loginState: ', loginState)
        props.setLoginState(loginState);
    }

    const clearPassword = () => {
        const loginDetailsLocal = { ...loginDetails }
        loginDetailsLocal.password = null
        setLoginDetails(loginDetailsLocal)
    }

    const setUsername = username => {
        const loginDetailsLocal = { ...loginDetails }
        loginDetailsLocal.username = username
        setLoginDetails(loginDetailsLocal)
    }

    const setPassword = password => {
        const loginDetailsLocal = { ...loginDetails }
        loginDetailsLocal.password = password
        setLoginDetails(loginDetailsLocal)
    }

    const updateLoginStateLoginSuccess = () => {
        updateLoginStateWithUserDetails(true, LOGIN_STATUS.LOGGED_IN, null, null, loginDetails.username, loginDetails.password, loginDetails.username)
    }

    const updateLoginStatePasswordResetRequired = () => {
        updateLoginStateWithUserDetails(true, LOGIN_STATUS.CHANGE_PASSWORD, null, null, loginDetails.username, loginDetails.password, loginDetails.username)
    }

    const updateLoginStateLoginFailed = errorMessage => {
        updateLoginStateWithUserDetails(false, LOGIN_STATUS.NOT_LOGGED_IN, null, errorMessage, loginDetails.username, null, null)
    }

    const forgotPasswordNewPasswordSubmitHandler = async (verificationCode, password) => {
        try {
            await forgotPasswordSubmit(loginDetails.username, password, verificationCode)
            setPassword(password)
            logInfo(`forgotPasswordNewPasswordSubmitHandler: password reset success. user can login using new password`)
            updateLoginState(false, LOGIN_STATUS.FORGOT_PASSWORD_VERIFICATION_SUCCESS, "Please login with your new password", null)
        } catch (error) {
            //console.log(error)
            const errorCode = error.code
            let errorMsg = null
            logError(`forgotPasswordNewPasswordSubmitHandler: exception thrown when submitting the new password with verification code - [${error.code}][${error.message}]`)
            if(errorCode === "CodeMismatchException") {
                errorMsg = "Verification code submitted is invalid. Please submit the correct one"
                updateLoginState(false, LOGIN_STATUS.FORGOT_PASSWORD_PENDING_VERIFICATION, null, errorMsg)
            }else {
                errorMsg = "Un-expected error occurred. Hence redirected back to login screen"
                updateLoginState(false, LOGIN_STATUS.NOT_LOGGED_IN, null, errorMsg)
            }
        }
    }

    const forgotPasswordRequestVerificationClickedHandler = async (username) => {
        try {
            const verificationCode = await forgotPassword(username);
            logInfo(`forgotPasswordRequestVerificationClickedHandler: verification code ${verificationCode} received for username: ${username}`)
            setUsername(username)
            updateLoginState(false, LOGIN_STATUS.FORGOT_PASSWORD_PENDING_VERIFICATION, null, null)
        } catch (error) {
            console.log(error)
            const errorCode = error.code
            let errorMsg = null
            logError(`forgotPasswordRequestVerificationClickedHandler: exception thrown when requesting forgot password verification code. Redirect back to login screen - [${error.code}][${error.message}]`)
            if(errorCode === "UserNotFoundException") {
                errorMsg = "Invalid username/email submitted. Redirected back to login screen"
                updateLoginState(false, LOGIN_STATUS.NOT_LOGGED_IN, null, errorMsg)
            }else if(errorCode === "LimitExceededException") {
                errorMsg = "Too may password reset attempts. Please try again after some time. Redirected back to login screen"
                updateLoginState(false, LOGIN_STATUS.NOT_LOGGED_IN, null, errorMsg)
            }else {
                errorMsg = "Un-expected error occurred. Hence redirected back to login screen"
                updateLoginState(false, LOGIN_STATUS.NOT_LOGGED_IN, null, errorMsg)
            }
        }
    }

    const changePasswordHandler = async (newPassword) => {
        try {
            logInfo('changePasswordHandler: with new password')
            if(user) {
                const newUser = await requestChangePassword(user, newPassword)
                logError('changePasswordHandler: change password successful. new user: ', newUser)
                setUser(newUser)
                updateLoginState(true, LOGIN_STATUS.LOGGED_IN, null)
            }else {
                logError('changePasswordHandler: existing user is null hence unable to change the password. may required to log in again')
                updateLoginState(false, LOGIN_STATUS.NOT_LOGGED_IN, "You should login before changing the current password")
            }
        } catch (error) {
            clearPassword()
            logError(`changePasswordHandler: exception thrown when changing the password. may required to log in again ${error.message}`)
            updateLoginState(false, LOGIN_STATUS.NOT_LOGGED_IN, `Error occurred when changing the password. Please re-login and try [${error.message}]`)
        }
    }

    const forgotPasswordClickHandler = () => {
        updateLoginState(false, LOGIN_STATUS.FORGOT_PASSWORD_REQUEST, null, null);
    }

    const loginHandler = async () => {
        try {
            logInfo('loginHandler: loginDetails: ', loginDetails)
            const username = loginDetails.username;
            const password = loginDetails.password;
            const user = await userSignIn(username, password)
            setUser(user)
            if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {

            } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                logInfo("loginHandler: Password reset required!!!");
                updateLoginStatePasswordResetRequired()
            } else if (user.challengeName === 'MFA_SETUP') {

            } else {
                logInfo("loginHandler: Logged in...!!!");
                await getAuthenticatedUser()
                await loadUserSettings()
                updateLoginStateLoginSuccess()
            }
        } catch (err) {
            clearPassword()
            updateLoginStateLoginFailed(`Login unsuccessful - ${err.message}`)
            if (err.code === 'UserNotConfirmedException') { }
            else if (err.code === 'PasswordResetRequiredException') { }
            else if (err.code === 'NotAuthorizedException') { }
            else if (err.code === 'UserNotFoundException') { }
            else { }
        }
    }

    let activeForm = null;
    if (props.loginState.isLoggedIn && props.loginState.loginStatus === LOGIN_STATUS.LOGGED_IN) {
        return <Redirect to="/dashboard" />
    }else if (props.loginState.isLoggedIn && props.loginState.loginStatus === LOGIN_STATUS.CHANGE_PASSWORD) {
        activeForm = <ChangePasswordForm password={loginDetails.password} changePasswordClicked={changePasswordHandler} title={APP_DISPLAY_NAME} />
    }else if (!props.loginState.isLoggedIn && (props.loginState.loginStatus === LOGIN_STATUS.FORGOT_PASSWORD_REQUEST || props.loginState.loginStatus === LOGIN_STATUS.FORGOT_PASSWORD_PENDING_VERIFICATION)) {
        activeForm = <ForgotPassword loginStatus={props.loginState.loginStatus} title={APP_DISPLAY_NAME}
                                        forgotPasswordRequestVerificationClicked={forgotPasswordRequestVerificationClickedHandler} 
                                        forgotPasswordNewPasswordSubmit={forgotPasswordNewPasswordSubmitHandler}/>
    }else {
        activeForm =  <LoginForm  username={loginDetails.username}
        password={loginDetails.password}
        title={APP_DISPLAY_NAME}
        loginDetailsChanged={loginDetailsChangedHandler}
        loginButtonClicked={loginHandler} 
        forgotPasswordLinkClicked={forgotPasswordClickHandler}
        clearErrorAndSuccessMessages={clearLoginStateSuccessAndErrorMessages}/>
    }

    const alertStyle = {
        fontSize: 'small'
    }

    const loginError = props.loginState.errorMessage ?
        <Alert key="loginError" variant="danger" style={alertStyle}>
            {props.loginState.errorMessage}
        </Alert>
        : null

    const loginSuccess = props.loginState.successMessage ?
        <Alert key="loginSuccess" variant="success" style={alertStyle}>
            {props.loginState.successMessage}
        </Alert>
        : null

    return (

        <div className={classes.ApplicationLogin}>
            {loginError}
            {loginSuccess}
            {activeForm}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        loginDetailsState: state.loginDetailsState,
        loginState: state.loginState,
        userSettingsState: state.userSettingsState
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLoginDetailsState: payload =>
            dispatch({ type: SET_LOGIN_DETAILS_STATE, payload: payload }),
        setLoginState: payload =>
            dispatch({ type: SET_LOGIN_STATE, payload: payload }),
        setUserSettingsState: payload =>
            dispatch({ type: SET_USER_SETTINGS_STATE, payload: payload })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationLogin);