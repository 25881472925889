import React, { useEffect } from 'react';
import classes from './App.module.css';
import { Container } from 'react-bootstrap';
import { BrowserRouter } from 'react-router-dom';
import { connect } from "react-redux"
import Routes from './Routes'
import { COGNITO_USER_SETTINGS_KEY, COGNITO_EMAIL_KEY } from "../constants/Constants"

//actions
import { SET_LOGIN_DETAILS_STATE } from "../redux/actions"
import { SET_LOGIN_STATE } from "../redux/actions"
import { SET_USER_SETTINGS_STATE } from "../redux/actions"
import { SET_TIME_ZONE_SETTINGS_STATE } from "../redux/actions"
import { getUserSettings, getAuthenticatedUser } from "../util/login/application-login-utils"
import { convertMinuteOffsetToLocalTimeZone } from "../util/date/date-utils"
import {logInfo, logError} from '../util/log/logger'

const App = props => {

  console.log(`App: props: `, props)

  const loadTimeZoneSettings = () => {
    const state = { ...props.timeZoneSettingsState }
    const offset = new Date().getTimezoneOffset() * -1;
    const timezone = convertMinuteOffsetToLocalTimeZone(offset);
    console.log("timezone offset: " + offset + ", timezone: " + timezone);
    state.localTimeOffSetToGmt = offset;
    state.localTimeZone = timezone;
    props.setTimeZoneSettingsState()
  }

  const loadUserSettings = async () => {
    try {
      const info = await getUserSettings()
      logInfo('loadUserSettings: info: ', info)
      const userSettingsStr = info ? info.attributes[COGNITO_USER_SETTINGS_KEY] : null
      if(userSettingsStr) {
        const userSettingsObj = JSON.parse(userSettingsStr);
        logInfo("loadUserSettings: userSettings", userSettingsObj);
        props.setUserSettingsState(userSettingsObj)
      }

      const userEmail = info ? info.attributes[COGNITO_EMAIL_KEY] : null
      if(userEmail !== null) {
        const userSettingsState = { ...props.userSettingsState}
        userSettingsState.email = userEmail
        props.setUserSettingsState(userSettingsState)  
      }
    } catch (error) {
      logError("loadUserSettings: error", error);
    }
  }

  const getCurrentUser = async () => {
    const user = await getAuthenticatedUser()
    const loginState = { ...props.loginState }
    loginState.isLoggedIn = true;
    loginState.loginError = null;
    loginState.userDetails.username = user.username;
    loginState.userDetails.password = null;
    loginState.userDetails.email = user.username;
    props.setLoginState(loginState);
    logInfo(loginState)
    return user;
  }

  const checkForExistingUserSession = async () => {
    try {
      await getCurrentUser()
      try {
        await loadUserSettings()
      } catch (error) {
        logError("checkForExistingUserSession | loadUserSettings - error", error);
      }
    } catch (error) {
      logError("checkForExistingUserSession | getCurrentUser - error", error);
    }
  }

  useEffect(() => {
    loadTimeZoneSettings()
  }, [])


  useEffect(() => {
    if (!props.loginState.isLoggedIn) {
      checkForExistingUserSession()
    }
  }, [])


  const redirect = !props.loginState.isLoggedIn ?  null : null 
  
  return (
    <BrowserRouter>
      {redirect}
      <Container fluid className={classes.App}>
        <Routes />
      </Container>
    </BrowserRouter>
  );
}

const mapStateToProps = (state, ownProps) => {
  const returnProps = {
    loginState: state.loginState,
    timeZoneSettingsState: state.timeZoneSettingsState,
    userSettingsState: state.userSettingsState,
    ...ownProps
  }
  console.log(returnProps)
  return returnProps;
}

const mapDispatchToProps = dispatch => {
  return {
    setLoginDetailsState: payload =>
      dispatch({ type: SET_LOGIN_DETAILS_STATE, payload: payload }),
    setLoginState: payload =>
      dispatch({ type: SET_LOGIN_STATE, payload: payload }),
    setUserSettingsState: payload =>
      dispatch({ type: SET_USER_SETTINGS_STATE, payload: payload }),
    setTimeZoneSettingsState: payload =>
      dispatch({ type: SET_TIME_ZONE_SETTINGS_STATE, payload: payload })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
