import React from 'react';
import classes from './style.module.css'
import { HORSE_TABLE_COLUMNS_STATIC } from '../../../constants/Constants'
import OddResultCell from './OddResultCell'
import LiveScore from './LiveScore'
import { getCurrentScore } from '../../../service/backend-api-service';

const League = (props) => {
    const rowIndex = props.index + 1;
    const id = props.item.time;
    const columnList = props.finalColumns;
    const regionNames = new Intl.DisplayNames(
        ['en'], {type: 'region'}
      );
    const cells = columnList.map(column => {
        let cell = null;
        const cellKey = id + "_" + column.id
        //console.log(props.item.raceId)
        switch (column.id) {
            case HORSE_TABLE_COLUMNS_STATIC.ROW_NUM.id:
                cell = <th style={{ width: "24px" }} key={cellKey} className={classes.LeagueTdTh} scope="row">{rowIndex}</th>
                break;
            case HORSE_TABLE_COLUMNS_STATIC.DATE.id:
                cell = <td key={cellKey} className={classes.LeagueTdTh}>{props.item.bfDate}</td>
                break;
            case HORSE_TABLE_COLUMNS_STATIC.COUNTRY.id:
                cell = <td key={cellKey} className={classes.LeagueTdTh}>
                    {props?.item?.bfCountryCode   ? 
                        (props.item.bfCountryCode === 'US' || props.item.bfCountryCode == 'GB') ?
                            props.item.bfCountryCode :
                            regionNames.of(props.item.bfCountryCode) : 
                            props.item.bfCompetition ? props.item.bfCompetition.substring(0,10) : ''}
                </td>
                break;
            case HORSE_TABLE_COLUMNS_STATIC.TIME.id:
                cell = <td key={cellKey} className={classes.LeagueTdTh}>{props.item.bfTime}</td>
                break;
            case HORSE_TABLE_COLUMNS_STATIC.SCORE.id:
                cell = <td key={cellKey} className={classes.LeagueTdTh}>
                    <LiveScore eventId={props.item.bfEventId} matchStatusChangeHandler={props.matchStatusChangeHandler} />
                </td>
                break;
            case HORSE_TABLE_COLUMNS_STATIC.EVENT.id:
                cell = <td key={cellKey} className={classes.LeagueTdTh}>{props.item.bfHomeTeam} - {props.item.bfAwayTeam}</td>
                break;
            case HORSE_TABLE_COLUMNS_STATIC.ODD_DETAILS.id:
                let part = props.item;
                //console.log(JSON.stringify(part))
                let detailsCurrent = "-,-"
                let detailsStart = "-,-"
                let detailsHalfTime = "-,-"

                if (part.bfEventId) {
                    let status = getCurrentScore(part.bfEventId)
                    detailsStart = <OddResultCell status={status} home={props.item.bfHomeTeam} away={props.item.bfAwayTeam} time={props.item.bfTime} date={props.date} oddChangeHandler={props.oddChangeHandler} type="start" eventId={part.bfEventId} />
                    detailsCurrent = <OddResultCell status={status}  home={props.item.bfHomeTeam} away={props.item.bfAwayTeam} time={props.item.bfTime}  date={props.date} oddChangeHandler={props.oddChangeHandler} type="current" eventId={part.bfEventId} />
                    detailsHalfTime = <OddResultCell status={status} home={props.item.bfHomeTeam} away={props.item.bfAwayTeam} time={props.item.bfTime}  date={props.date} oddChangeHandler={props.oddChangeHandler} type="ht" eventId={part.bfEventId} />
                }
                cell = <td key={cellKey} className={classes.LeagueTdTh}>{detailsStart}
                    {detailsCurrent}{detailsHalfTime}</td>
                break;
            case HORSE_TABLE_COLUMNS_STATIC.COMB_GOALS_SCORED_CONCEDED.id:
                cell = <td key={cellKey} className={classes.LeagueTdTh}>{props.item.combGoalsScoredConc}</td>
                break;
            case HORSE_TABLE_COLUMNS_STATIC.COMB_OVER_TWO_POINT_FIVE.id:
                    cell = <td key={cellKey} className={classes.LeagueTdTh}>{props.item.combOverTwoPointFive}</td>
                    break; 
            case HORSE_TABLE_COLUMNS_STATIC.COMB_BTTS.id:
                    cell = <td key={cellKey} className={classes.LeagueTdTh}>{props.item.combBtts}</td>
                    break;    
            case HORSE_TABLE_COLUMNS_STATIC.HOME_WIN_PERCENT.id:
                cell = <td key={cellKey} className={classes.LeagueTdTh}>
                    {props.item.prediction ? props.item.prediction.homePercent : '-'}
                </td>
                break;
                case HORSE_TABLE_COLUMNS_STATIC.DRAW_WIN_PERCENT.id:
                    cell = <td key={cellKey} className={classes.LeagueTdTh}>
                        {props.item.prediction ? props.item.prediction.drawPercent : '-'}
                    </td>
                    break;
                    
                    case HORSE_TABLE_COLUMNS_STATIC.AWAY_WIN_PERCENT.id:
                cell = <td key={cellKey} className={classes.LeagueTdTh}>
                    {props.item.prediction ? props.item.prediction.awayPercent : '-'}
                </td>
                break;

                case HORSE_TABLE_COLUMNS_STATIC.ADVICE.id:
                cell = <td key={cellKey} className={classes.LeagueTdTh}>
                    {props.item.prediction ? props.item.prediction.advice : '-'}
                </td>
                break;
            default:
                cell = <td key={cellKey} className={classes.LeagueTdTh}>-</td>
        }
        return cell;
    })

    return (
        <tr onClick={() => props.clickHandler(props.item)} className={classes.League}>
            {cells}
        </tr>
    )
}

export default League;