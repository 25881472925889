
import moment from 'moment'

const currentDateStr = () =>  moment().format('YYYYMMDD')

const passStringToDate = (date) =>  moment(date, 'YYYYMMDD').toDate()

const passStringToDateWithFormat = (date, format) =>  moment(date, format).toDate()

const passDateToString = (date) => moment(date).format('YYYYMMDD');

const passDateToStringWithFormat = (date, format) => moment(date).format(format);

const getPreviousNthDayStr = (n) => moment().subtract(n, 'days').format('YYYYMMDD')

const getFutureNthDayStr = (n) => moment().add(n, 'days').format('YYYYMMDD');


const convertMinuteOffsetToLocalTimeZone = num => {
    let sign = "+";
    if (num < 0) sign = "-"
    num = Math.abs(num);
    const hours = Math.floor(num / 60);
    const minutes = num % 60;
    return "GMT" + sign + (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes);
  }

export {currentDateStr,
        getPreviousNthDayStr,
        getFutureNthDayStr,
        passStringToDate,
        passDateToString,
        passStringToDateWithFormat,
        convertMinuteOffsetToLocalTimeZone,
        passDateToStringWithFormat
}