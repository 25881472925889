import React from "react";
import { Route, Switch } from "react-router-dom";
import LoginView from './LoginView'
import Dashboard from './Dashboard'
import AppliedRoute from "./AppliedRoute";
import NotFound from "./NotFound";

export default ({ childProps }) =>
  <Switch>
  <AppliedRoute path="/login" exact component={LoginView}  props={childProps}/>
  <AppliedRoute path="/dashboard" exact component={Dashboard}  props={childProps}/>
  <AppliedRoute path="/" component={LoginView}  props={childProps}/>
  <Route component={NotFound} />
  </Switch>;
