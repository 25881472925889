import React from 'react';
import { Row } from 'react-bootstrap';
import classes from './style.module.css'
import Header from "../../components/Header"

const HeaderRow = (props) => {
    return (
        <Row className={classes.HeaderRow} noGutters>
            {props.active ? <Header matchFilterHandler={props.matchFilterHandler} 
                  raceSelectHandler={props.raceSelectHandler} logoutClicked={props.logoutClicked} /> : null} 
        </Row>
    )
}

export default HeaderRow;