
import React from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';
// import classes from './style.module.css';
import { LOGIN_PAGE_FORM_FIELDS } from '../../../constants/Constants'
import PropTypes from 'prop-types';

const LoginForm = props => {

    let forgotPassword = <Button variant="link" onClick={props.forgotPasswordLinkClicked}>Forgot Password</Button>
    if(props.enableForgotPassword === false) forgotPassword = null

    return(
        <Form>
                <p style={{color: 'black', fontWeight: 'bold', fontSize: '15px'}}>{props.title} - Login</p>
                <Form.Group controlId="loginFormUsernameInput">
                    <Form.Label>{LOGIN_PAGE_FORM_FIELDS.USERNAME_TEXT.label}</Form.Label>
                    <Form.Control
                        key={LOGIN_PAGE_FORM_FIELDS.USERNAME_TEXT.key}
                        type="text"
                        placeholder={LOGIN_PAGE_FORM_FIELDS.USERNAME_TEXT.placeHolder}
                        value={props.username === null ? "" : props.username}
                        onChange={(event) => { props.clearErrorAndSuccessMessages(); props.loginDetailsChanged(LOGIN_PAGE_FORM_FIELDS.USERNAME_TEXT.key, event.target.value);  }} />
                    <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                    </Form.Text>
                </Form.Group>

                <Form.Group controlId="loginFormPasswordInput">
                    <Form.Label>{LOGIN_PAGE_FORM_FIELDS.PASSWORD_TEXT.label}</Form.Label>
                    <Form.Control
                        key={LOGIN_PAGE_FORM_FIELDS.PASSWORD_TEXT.key}
                        type="password"
                        placeholder={LOGIN_PAGE_FORM_FIELDS.PASSWORD_TEXT.placeholder}
                        value={props.password === null ? "" : props.password}
                        onChange={(event) => { props.clearErrorAndSuccessMessages(); props.loginDetailsChanged(LOGIN_PAGE_FORM_FIELDS.PASSWORD_TEXT.key, event.target.value) }} />
                </Form.Group>
                <Form.Group as={Row} controlId="loginFormSavePasswordCheckbox">
                    <Col>
                    <Form.Check
                        key={LOGIN_PAGE_FORM_FIELDS.SAVE_PASSWORD_FLAG_CHECK_BOX.key}
                        type="checkbox"
                        label={LOGIN_PAGE_FORM_FIELDS.SAVE_PASSWORD_FLAG_CHECK_BOX.label}
                        onChange={(event) => { props.clearErrorAndSuccessMessages(); props.loginDetailsChanged(LOGIN_PAGE_FORM_FIELDS.SAVE_PASSWORD_FLAG_CHECK_BOX.key, event.target.value === "on" ? true : false) }} />
                    </Col>
                    <Col style={{textAlign: 'right'}}>{forgotPassword}</Col>
                </Form.Group>
                <Button variant="primary"
                    onClick={props.loginButtonClicked}>
                    Sign In
                </Button>
            </Form>
    )
}

LoginForm.propTypes = {
    username: PropTypes.string,
    password: PropTypes.string,
    enableForgotPassword: PropTypes.bool,
    loginDetailsChanged: PropTypes.func.isRequired,
    loginButtonClicked: PropTypes.func.isRequired
}

export default LoginForm