import React, { useState } from 'react';
import classes from './style.module.css';
import PropTypes from 'prop-types';
import { logInfo/*, logError*/ } from '../../../util/log/logger'
import { Form, Button, Row } from 'react-bootstrap';
import { FORGOT_PASSWORD_FORM_FIELDS } from '../../../constants/Constants'
import { LOGIN_STATUS } from '../../../constants/Constants'

const ForgotPasswordForm = props => {

    const [username, setUsername] = useState("");
    const [passwordNew, setPasswordNew] = useState("");
    const [passwordNewVerify, setPasswordNewVerify] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [inputErrorMessage, setInputErrorMessage] = useState(null);

    const greenTextStyle = { color: 'green', fontSize: '12px' }

    const validateInputs = () => {
        return passwordNew === passwordNewVerify
    }

    const requestVerificationCodeHandler = async () => {
        await props.forgotPasswordRequestVerificationClicked(username)
    }


    const submitNewPasswordHandler = async () => {
        if (validateInputs()) {
            logInfo("submitNewPasswordHandler | new and confirm passwords matched")
            setInputErrorMessage(null)
            await props.forgotPasswordNewPasswordSubmit(verificationCode, passwordNew)
        } else {
            logInfo("submitNewPasswordHandler | Password mismatch or incorrect verification code. Please check and re-enter again")
            setInputErrorMessage("Password mismatch or incorrect verification code. Please check and re-enter again")
        }
    }

    let fieldErrors = null;
    if (inputErrorMessage) {
        fieldErrors = <Row className={classes.FieldErrors}>
            <p>{inputErrorMessage}</p>
        </Row>
    }

    let activeForm = null
    if (props.loginStatus === LOGIN_STATUS.FORGOT_PASSWORD_REQUEST) {
        activeForm = <>
            <p style={greenTextStyle}>Provide registered username/email to receive password reset verification code</p>
            <Form.Group controlId="loginFormUsernameInput">
                <Form.Label>{FORGOT_PASSWORD_FORM_FIELDS.USERNAME_TEXT.label}</Form.Label>
                <Form.Control
                    key={FORGOT_PASSWORD_FORM_FIELDS.USERNAME_TEXT.key}
                    type="text"
                    placeholder={FORGOT_PASSWORD_FORM_FIELDS.USERNAME_TEXT.placeHolder}
                    value={username}
                    onChange={(event) => { setUsername(event.target.value) }} />
                <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                                </Form.Text>
            </Form.Group>
            <Button variant="primary"
                onClick={requestVerificationCodeHandler}>
                {FORGOT_PASSWORD_FORM_FIELDS.REQUEST_VERIFICATION_CODE_BUTTON.label}
            </Button>
        </>
    } else if (props.loginStatus === LOGIN_STATUS.FORGOT_PASSWORD_PENDING_VERIFICATION) {
        activeForm = <>
            <p style={greenTextStyle}>Enter verification code received to your email and Provide your desired password</p>
            <Form.Group controlId="loginFormVerificationCodeInput">
                <Form.Label>{FORGOT_PASSWORD_FORM_FIELDS.VERIFICATION_CODE_TEXT.label}</Form.Label>
                <Form.Control
                    key={FORGOT_PASSWORD_FORM_FIELDS.VERIFICATION_CODE_TEXT.key}
                    type="password"
                    placeholder={FORGOT_PASSWORD_FORM_FIELDS.VERIFICATION_CODE_TEXT.placeholder}
                    value={verificationCode}
                    onChange={(event) => { setVerificationCode(event.target.value); setInputErrorMessage(null) }} />
            </Form.Group>

            <Form.Group controlId="loginFormPasswordInputNew">
                <Form.Label>{FORGOT_PASSWORD_FORM_FIELDS.PASSWORD_NEW_TEXT.label}</Form.Label>
                <Form.Control
                    key={FORGOT_PASSWORD_FORM_FIELDS.PASSWORD_NEW_TEXT.key}
                    type="password"
                    placeholder={FORGOT_PASSWORD_FORM_FIELDS.PASSWORD_NEW_TEXT.placeholder}
                    value={passwordNew}
                    onChange={(event) => { setPasswordNew(event.target.value); setInputErrorMessage(null) }} />
            </Form.Group>

            <Form.Group controlId="loginFormPasswordInputNewVerify">
                <Form.Label>{FORGOT_PASSWORD_FORM_FIELDS.PASSWORD_NEW_VERIFY_TEXT.label}</Form.Label>
                <Form.Control
                    key={FORGOT_PASSWORD_FORM_FIELDS.PASSWORD_NEW_VERIFY_TEXT.key}
                    type="password"
                    placeholder={FORGOT_PASSWORD_FORM_FIELDS.PASSWORD_NEW_VERIFY_TEXT.placeholder}
                    value={passwordNewVerify}
                    onChange={(event) => { setPasswordNewVerify(event.target.value); setInputErrorMessage(null) }} />
            </Form.Group>
            {fieldErrors}
            <Button variant="primary"
                onClick={submitNewPasswordHandler}>
                {FORGOT_PASSWORD_FORM_FIELDS.SUBMIT_BUTTON.label}
            </Button>
        </>
    }

    return (
        <Form className={classes.ChangePasswordForm}>
            <p style={{ color: 'black', fontWeight: 'bold', fontSize: '15px' }}>{props.title} - Forgot Password</p>
            {activeForm}
        </Form>
    )
}

ForgotPasswordForm.propTypes = {
    loginStatus: PropTypes.string.isRequired,
    forgotPasswordRequestVerificationClicked: PropTypes.func.isRequired,
    forgotPasswordNewPasswordSubmit: PropTypes.func.isRequired,
}

export default ForgotPasswordForm