
const logInfo = (message, object1, object2) => {
    if(message && object1 && object2) console.log(message, object1, object2)
    else if(message && object1) console.log(message, object1)
    else if(message) console.log(message)
}

const logError = (message, object1, object2) => {
    const css =  'color: #FC17FC';
    if(message && object1 && object2) console.log('%c' + message, css, object1, object2)
    else if(message && object1) console.log('%c' + message, css, object1)
    else if(message) console.log('%c' + message, css)
}

module.exports = {logInfo, logError}